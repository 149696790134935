import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageService } from '../../services';
import { Helmet } from 'react-helmet';
import { BannerProps } from '../../types';
import { ResImg } from '../global';

const Banner: React.FunctionComponent<BannerProps> = ({
  src,
  srcAlt,
  subtitle,
  title,
}) => {
  const {
    site,
  }: {
    site: {
      siteMetadata: {
        origin: string;
      };
    };
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            origin
          }
        }
      }
    `,
  );
  const bannerImageSrc = ImageService.toSmallest(src, 1920, 1920);
  const metaOGImage = site.siteMetadata.origin + bannerImageSrc;
  return (
    <section className="banner">
      <Helmet
        meta={[
          {
            property: `og:image`,
            content: metaOGImage,
          },
          {
            property: `twitter:image`,
            content: metaOGImage,
          },
        ]}
        link={[
          {
            rel: 'preload',
            as: 'image',
            href: bannerImageSrc,
          },
        ]}
      />
      <ResImg
        className="banner--img"
        alt={srcAlt || ''}
        src={src}
        minWidth={1920}
      />
      <div className="banner--overlay"></div>
      <div className="wrapper">
        <div className="banner--inner">
          {title ? <h1 className="banner--title">{title}</h1> : ''}
          {subtitle ? <h2 className="banner--subtitle">{subtitle}</h2> : ''}
        </div>
      </div>
    </section>
  );
};

export default Banner;
