import * as React from 'react';
import Helmet from 'react-helmet';
import GoogleFonts from './googleFonts';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { ImageService } from '../../services';

interface Props {
  description: string;
  lang: string;
  meta: Array<{
    name: string;
    content: string;
  }>;
  title: string;
  OGImageUri?: string;
  noPinkertonInTitle?: boolean;
}

const HeadMeta: React.FunctionComponent<Props> = ({
  description,
  OGImageUri,
  lang,
  meta,
  title,
  noPinkertonInTitle,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            origin
          }
        }
      }
    `,
  );
  const metaDescription = description || site.siteMetadata.description;

  let metaOGImage;

  if (OGImageUri) {
    metaOGImage =
      site.siteMetadata.origin +
      ImageService.toSmallest(OGImageUri, 1920, 1920);
  }

  const { pathname } = useLocation();

  return (
    <>
      <GoogleFonts />
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s${
          noPinkertonInTitle ? ' ' : ' | ' + site.siteMetadata.title
        } `}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: metaOGImage ? `${metaOGImage}` : undefined,
          },
          {
            property: `twitter:image`,
            content: metaOGImage ? `${metaOGImage}` : undefined,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            property: `og:url`,
            content: `${site.siteMetadata.origin}${pathname}`,
          },
        ].concat(meta)}
        script={[
          {
            type: 'text/javascript',
            src: 'https://js-eu1.hsforms.net/forms/embed/v2.js',
            async: true,
            defer: true,
          },
          {
            type: 'text/javascript',
            src: '//script.crazyegg.com/pages/scripts/0095/2505.js',
            async: true,
          },
        ]}
        link={[
          {
            rel: `canonical`,
            href: `${site.siteMetadata.origin}${pathname}`,
          },
        ]}
      ></Helmet>
    </>
  );
};

export default HeadMeta;
