import { Link, navigate } from 'gatsby';
import React from 'react';
import { HeaderItems } from '../../data';
import { Image } from '../global';

interface Props {
  onHamburgerToggle?: () => void;
}

const Header: React.FunctionComponent<Props> = ({ onHamburgerToggle }) => {
  const [isSearchVisible, setIsSearchVisible] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState('');
  const searchInputRef = React.createRef<HTMLInputElement>();

  return (
    <header
      className="header"
      onMouseLeave={() => {
        setActiveItem('');
      }}
    >
      <div className="header--inner">
        <Link
          to="/"
          className="header--logo"
          onClick={() => {
            setActiveItem('');
          }}
        >
          <img width={60} height={65} src="/logo.svg" alt="Pinkerton Logo" />
        </Link>
        <nav className="header--nav">
          <ul className="header--nav-list">
            {HeaderItems.map((headerItem, headerItemIndex) => {
              const table = HeaderItems.find(
                (e) => e.name === headerItem.name,
              )?.table;

              return (
                <li key={headerItemIndex} className="header--nav-list-item">
                  <button
                    onMouseOver={() => {
                      if (headerItem.name !== activeItem) {
                        setActiveItem(headerItem.name);
                      }
                      if (isSearchVisible) {
                        setIsSearchVisible(false);
                      }
                    }}
                    className={`btn btn_block ${
                      activeItem === headerItem.name ? 'active' : ''
                    }`}
                  >
                    <span>{headerItem.name}</span>
                  </button>
                  <div
                    className={`header--subNav ${
                      activeItem === headerItem.name ? 'active' : ''
                    } ${headerItem.name.toLowerCase().replace(/ /g, '-')}`}
                    onMouseLeave={() => {
                      setActiveItem('');
                    }}
                  >
                    <div
                      className={`header--subNav_${headerItem.name
                        .toLowerCase()
                        .replace(/ /g, '-')}`}
                    >
                      {headerItem.head && (
                        <div className="header--subNav-head">
                          {headerItem.head.label && (
                            <div className="header--subNav-grid-item-label">
                              {headerItem.head.label}
                            </div>
                          )}
                          <div className="header--subNav-head-list">
                            {headerItem.head.items.map((item, index) => {
                              return (
                                <div
                                  className="header--subNav-head-item"
                                  key={index}
                                >
                                  {item.label && item.url && (
                                    <Link
                                      to={item.url}
                                      className="header--subNav-head-item-link"
                                      onClick={() => {
                                        setActiveItem('');
                                      }}
                                    >
                                      {item.label}
                                    </Link>
                                  )}
                                  {item.social && (
                                    <div className="header--subNav-grid-item-list-item-social">
                                      {item.social.map(
                                        (
                                          navItemSocialLink,
                                          navItemSocialLinkIndex,
                                        ) => {
                                          return (
                                            <a
                                              href={navItemSocialLink.url}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              key={navItemSocialLinkIndex}
                                              onClick={() => {
                                                setActiveItem('');
                                              }}
                                            >
                                              <Image
                                                src={navItemSocialLink.icon}
                                                alt="Icon"
                                              />
                                            </a>
                                          );
                                        },
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      <div className="header--subNav-grid">
                        {table?.map((item, index) => {
                          return (
                            <div
                              className="header--subNav-grid-item"
                              key={index}
                            >
                              {item.label &&
                                (item.url ? (
                                  <Link
                                    to={item.url}
                                    className="header--subNav-grid-item-label header--subNav-grid-item-label_link"
                                    onClick={() => {
                                      setActiveItem('');
                                    }}
                                  >
                                    {item.label}
                                  </Link>
                                ) : (
                                  <div className="header--subNav-grid-item-label">
                                    {item.label}
                                  </div>
                                ))}
                              <ul className="header--subNav-grid-item-list">
                                {item.items.map((navItem, navItemIndex) => {
                                  return (
                                    <li
                                      className="header--subNav-grid-item-list-item"
                                      key={navItemIndex}
                                    >
                                      {navItem.label &&
                                        (navItem.url ? (
                                          navItem.url.startsWith('/') ? (
                                            <Link
                                              to={navItem.url}
                                              className="header--subNav-grid-item-list-item-label header--subNav-grid-item-list-item-label_link"
                                              onClick={() => {
                                                setActiveItem('');
                                              }}
                                            >
                                              <span>{navItem.label}</span>
                                            </Link>
                                          ) : (
                                            <a
                                              href={navItem.url}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="header--subNav-grid-item-list-item-label header--subNav-grid-item-list-item-label_link"
                                              onClick={() => {
                                                setActiveItem('');
                                              }}
                                            >
                                              <span>{navItem.label}</span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1693.583 273.2 24.45 14.617"
                                              >
                                                <g
                                                  fill="transparent"
                                                  stroke="#fff"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="2"
                                                  data-name="Group 3"
                                                >
                                                  <path
                                                    d="m1710.652 274.63 5.871 5.87-5.87 5.87"
                                                    data-name="Path 1"
                                                  />
                                                  <path
                                                    d="M1716.588 280.424H1695"
                                                    data-name="Line 3"
                                                  />
                                                </g>
                                              </svg>
                                            </a>
                                          )
                                        ) : (
                                          <div className="header--subNav-grid-item-inner">
                                            <div className="header--subNav-grid-item-label">
                                              {navItem.label}
                                            </div>
                                            {navItem.items &&
                                              navItem.items.length > 0 && (
                                                <ul>
                                                  {navItem.items.map(
                                                    (
                                                      subNavItem,
                                                      subNavItemIndex,
                                                    ) => {
                                                      return (
                                                        <li
                                                          key={subNavItemIndex}
                                                        >
                                                          <Link
                                                            to={subNavItem.url}
                                                            className="header--subNav-grid-item-list-item-label header--subNav-grid-item-list-item-label_link"
                                                            onClick={() => {
                                                              setActiveItem('');
                                                            }}
                                                          >
                                                            <span>
                                                              {subNavItem.label}
                                                            </span>
                                                          </Link>
                                                        </li>
                                                      );
                                                    },
                                                  )}
                                                </ul>
                                              )}
                                          </div>
                                        ))}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
            <li className="header--nav-list-item">
              <button
                onClick={() => {
                  setActiveItem('');
                  setIsSearchVisible(!isSearchVisible);
                }}
                className="btn btn_block btn_search"
              >
                <span>
                  <img
                    width={25}
                    height={25}
                    src={`/icons/search${isSearchVisible ? '-close' : ''}.svg`}
                    alt="Search"
                  />
                </span>
              </button>
            </li>
            <li className="header--nav-list-item">
              <Link
                className="btn btn_block darkBg"
                to="/careers/current-openings"
                onMouseOver={() => {
                  if (activeItem !== '') {
                    setActiveItem('');
                  }
                }}
                onClick={() => {
                  setActiveItem('');
                }}
              >
                <span>Careers</span>
              </Link>
            </li>
          </ul>
        </nav>
        {isSearchVisible && (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (searchInputRef.current) {
                navigate(
                  `/search/?search=${encodeURIComponent(
                    searchInputRef.current.value,
                  )}`,
                );
              }
            }}
            className="header--form"
          >
            <label>
              <span className="sr-only">Search:</span>
              <input
                type="text"
                name="search"
                placeholder="Search..."
                autoFocus
                ref={searchInputRef}
              ></input>
            </label>
            <button className="btn btn_block" type="submit">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="904.567 733.667 25.217 26.267"
                >
                  <g
                    fill="transparent"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    data-name="Group 1"
                  >
                    <path
                      strokeWidth="1.789"
                      d="M911.5 743.405a8.5 8.5 0 1 1 0 .09z"
                      data-name="Ellipse 1"
                    />
                    <path
                      strokeWidth="2"
                      d="m914 750.5-8 8"
                      data-name="Line 1"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </form>
        )}
        <button onClick={onHamburgerToggle} className="header--hamburger">
          <div className="header--hamburger-icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </button>
      </div>
    </header>
  );
};

export default Header;
