import { Link } from 'gatsby';
import React from 'react';
import { CrimeIndexHeroGroupType } from '../../../parser';
import { Image } from '../../index';

interface Props {
  hero: CrimeIndexHeroGroupType;
  dark: boolean;
  twoCol: boolean;
}

const CrimeIndexHero: React.FunctionComponent<Props> = ({
  hero,
  dark,
  twoCol,
}) => {
  return (
    <section
      className={`crimeIndexHero ${dark ? 'crimeIndexHero_dark' : ''} ${
        hero.full_width_image ? 'crimeIndexHero_fullWidthImage' : ''
      } ${twoCol ? 'crimeIndexHero_twoCol' : ''} ${
        !hero.image.uri ? 'crimeIndexHero_noImage moveFromHeader' : ''
      }`}
    >
      {hero.image.uri && (
        <Image
          src={hero.image.uri}
          alt={hero.image.alt_text}
          minWidth={1600}
          width={2000}
          height={1600}
          className="crimeIndexHero--image"
        />
      )}
      <div className="wrapper">
        {hero.subtitle && (
          <div className={`crimeIndexHero--subtitle`}>{hero.subtitle}</div>
        )}
        <h1
          className="crimeIndexHero--title"
          dangerouslySetInnerHTML={{ __html: hero.title }}
        />
        {hero.description && (
          <p className="crimeIndexHero--description">{hero.description}</p>
        )}
        {hero.buttons && hero.buttons.length > 0 && (
          <div className="crimeIndexHero--buttons">
            {hero.buttons.map((btn, index) => {
              return (
                <Link
                  to={btn.url}
                  key={index}
                  className={`btn btn_block ${
                    btn.theme.selected === 'TRANSPARENT'
                      ? 'btn_transparent'
                      : ''
                  } ${dark ? 'darkBg' : ''}`}
                >
                  <span>{btn.label}</span>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default CrimeIndexHero;
