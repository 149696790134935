import { Link } from 'gatsby';
import React from 'react';
import { SideNavComponentProps } from '../../types';
import RedirectBox from './redirect-box';

const SideNav: React.FunctionComponent<SideNavComponentProps> = ({
  title,
  isSticky,
  baseUri,
  pathname,
  items,
  redirectBox,
}) => {
  return (
    <aside
      className={`aside twoColSingle--aside sideNavigation ${
        isSticky ? 'sticky' : ''
      }`}
    >
      {title && <div className="aside--title">{title}</div>}
      <ul className="aside--list">
        {items.map((item, itemIndex) => {
          return (
            <li
              className={`aside--list-item ${
                pathname === item.slug ? 'active' : ''
              }`}
              key={itemIndex}
            >
              <Link to={`${item.excludeBaseUri ? '' : baseUri}/${item.slug}`}>
                {item.title}
              </Link>
              {pathname === item.slug ? (
                <ul className="aside--list_sub">
                  {item.subNavItems.map((subNavItem, subNavItemIndex) => {
                    return (
                      <li
                        className="aside--list-item_sub"
                        key={subNavItemIndex}
                      >
                        <a href={`#${subNavItem.path}`}>{subNavItem.label}</a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ''
              )}
            </li>
          );
        })}
      </ul>

      {redirectBox && (
        <RedirectBox
          title={redirectBox.title}
          path={redirectBox.path}
          description={redirectBox.description}
        />
      )}
    </aside>
  );
};

export default SideNav;
