import React from 'react';
import { HubspotWidgetType, WidgetDefaultProps } from '../../types';
import { HubspotForm } from '../global';

const HubspotFormWidget: React.FunctionComponent<
  HubspotWidgetType & WidgetDefaultProps
> = ({ form_id, dark, popup_button_label, className }) => {
  const [showPopupForm, setShowPopupForm] = React.useState(false);

  return popup_button_label ? (
    <div className="hubspotForm_widget hubspotForm_widget_popup">
      <button
        className="btn btn_block hubspotForm_widget--popupBtn"
        onClick={() => {
          setShowPopupForm(!showPopupForm);
        }}
      >
        <span>{popup_button_label}</span>
      </button>
      <div
        className="hubspotForm_widget--popupForm"
        style={{
          opacity: showPopupForm ? '1' : '0',
          pointerEvents: showPopupForm ? 'all' : 'none',
        }}
      >
        <div className="hubspotForm_widget--popupForm-main">
          <HubspotForm
            formId={form_id}
            className={`hubspotForm_widget ${className} ${
              dark ? 'darkBg' : ''
            }`}
          />
          <button
            className="hubspotForm_widget--popupForm-close"
            onClick={() => {
              setShowPopupForm(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeOpacity={0.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
        <div
          className="hubspotForm_widget--popupForm-overlay"
          onClick={() => {
            setShowPopupForm(false);
          }}
        />
      </div>
    </div>
  ) : (
    <HubspotForm
      formId={form_id}
      className={`hubspotForm_widget ${className} ${dark ? 'darkBg' : ''}`}
    />
  );
};

export default HubspotFormWidget;
