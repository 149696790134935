import { Link } from 'gatsby';
import React from 'react';
import * as uuid from 'uuid';
import { PostListItem } from '../../types';
import ResImg from './res-image';

interface Props {
  prefix?: string;
  chunkSize?: number;
  noTransform?: boolean;
  list: Array<PostListItem & { uuid: string }>;
}

const PostList: React.FunctionComponent<Props> = ({
  list,
  chunkSize,
  noTransform,
  prefix,
}: Props) => {
  const [chunkSizeVal, setChunkSizeVal] = React.useState(12);
  list.forEach((e) => {
    e.uuid = uuid.v4();
  });
  const [loadedPostsCount, setLoadedPostsCount] = React.useState(0);

  function setPinkertonScrollTo(index: string) {
    localStorage.setItem('pinkerton_scroll_to', index);
  }
  function loadMore() {
    setLoadedPostsCount(loadedPostsCount + chunkSizeVal);
  }

  React.useEffect(() => {
    if (chunkSize) {
      setChunkSizeVal(chunkSize);
    }
    const storagePinkertonScrollTo = localStorage.getItem(
      'pinkerton_scroll_to',
    );
    let itemIndex: number;
    if (storagePinkertonScrollTo && storagePinkertonScrollTo !== '0') {
      const pinkertonScrollTo = parseInt(storagePinkertonScrollTo);
      itemIndex = pinkertonScrollTo;
    } else {
      itemIndex = chunkSizeVal;
    }
    const nextItemIndex = Math.ceil(itemIndex / chunkSizeVal) * chunkSizeVal;
    setLoadedPostsCount(nextItemIndex);
  }, [chunkSizeVal]);

  return (
    <section
      className={`postList ${noTransform ? 'postList_noTransform' : ''}`}
    >
      <div className="postList--inner">
        {list.slice(0, loadedPostsCount).map((item, itemIndex) => (
          <article
            key={item.uuid}
            id={`article-${item.uuid}`}
            className="postList--item"
          >
            <Link
              className="postList--item-navBtn"
              to={(prefix || '') + '/' + item.slug}
              onClick={() => setPinkertonScrollTo('' + itemIndex)}
            >
              <div className="postList--item-cover">
                {item.cover_image !== '/media' && item.cover_image !== '' ? (
                  <ResImg
                    src={item.cover_image}
                    alt={
                      item.cover_image_alt_text
                        ? item.cover_image_alt_text
                        : item.title
                    }
                    minWidth={600}
                    width={1200}
                    height={600}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="postList--item-overlay"></div>
              <div className="postList--item-inner">
                <h3 className="postList--item-title">{item.title}</h3>
                <p className="postList--item-description">{item.description}</p>
              </div>
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-right"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </article>
        ))}
      </div>
      {list.length > loadedPostsCount ? (
        <div className="postList--pagination">
          <button
            className="btn btn_block"
            onClick={() => {
              loadMore();
            }}
          >
            <span>View more</span>
          </button>
        </div>
      ) : (
        <p className="postList--pagination-end">All posts loaded</p>
      )}
    </section>
  );
};

export default PostList;
