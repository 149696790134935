import { Link } from 'gatsby';
import React from 'react';

interface Props {
  items: Array<{
    label: string;
    path: string;
  }>;
}

const Breadcrumb: React.FunctionComponent<Props> = ({ items }) => {
  return (
    <nav className="breadcrumb">
      <ul className="breadcrumb--list">
        {items.map((item, index) => (
          <li className="breadcrumb--list-item" key={index}>
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
