import { Link } from 'gatsby';
import React from 'react';
import { LanguageSwitchType } from '../../parser';

const LanguageSwitch: React.FunctionComponent<LanguageSwitchType> = ({
  items,
}) => {
  return (
    <nav className="languageSwitch">
      <ul className="languageSwitch--list">
        {items.map((item, index) => (
          <li className="languageSwitch--list-item" key={index}>
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default LanguageSwitch;
