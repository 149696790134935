import React from 'react';
import { Image } from '..';
import {
  CrimeIndexMethodologyWidgetType,
  WidgetDefaultProps,
} from '../../types';

const CrimeIndexMethodologyWidget: React.FunctionComponent<
  CrimeIndexMethodologyWidgetType & WidgetDefaultProps
> = ({ className, content, image }) => {
  return (
    <div className={`crimeIndexMethodology ${className || ''}`}>
      <div className="wrapper">
        <div className="crimeIndexMethodology--inner">
          <Image
            src={image.uri}
            alt={image.alt_text}
            minWidth={1600}
            className="crimeIndexMethodology--image"
          />
          <div
            className="crimeIndexMethodology--content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </div>
  );
};

export default CrimeIndexMethodologyWidget;
