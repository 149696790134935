import React from 'react';

interface Props {
  level: string;
  id?: string;
  className?: string;
}

const Heading: React.FunctionComponent<Props> = ({
  id,
  className,
  level,
  children,
}) => {
  switch (level) {
    case 'H1': {
      return (
        <h1 id={id} className={className}>
          {children}
        </h1>
      );
    }
    case 'H2': {
      return (
        <h2 id={id} className={className}>
          {children}
        </h2>
      );
    }
    case 'H3': {
      return (
        <h3 id={id} className={className}>
          {children}
        </h3>
      );
    }
    case 'H4': {
      return (
        <h4 id={id} className={className}>
          {children}
        </h4>
      );
    }
    case 'H5': {
      return (
        <h5 id={id} className={className}>
          {children}
        </h5>
      );
    }
    case 'H6': {
      return (
        <h6 id={id} className={className}>
          {children}
        </h6>
      );
    }
    default: {
      throw Error(`Unsupported heading level "${level}".`);
    }
  }
};

export default Heading;
