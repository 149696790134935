import * as React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function GoogleFonts() {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            googleFonts
          }
        }
      }
    `,
  );

  const fontUri = site.siteMetadata.googleFonts;

  return (
    fontUri && (
      <Helmet>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link href={`${fontUri}&display=swap`} rel="stylesheet" />
        <link
          rel="stylesheet"
          href={`${fontUri}&display=swap`}
          media="print"
          onLoad={() => {
            this.media = 'all';
          }}
        />
        <noscript>
          {`
            <link
              rel="stylesheet"
              href="${fontUri}&display=swap" />
          `}
        </noscript>
      </Helmet>
    )
  )
}
