import React from 'react';
import {
  DownloadSourceWidget as DownloadSourceWidgetType,
  WidgetDefaultProps,
} from '../../types';

const DownloadSourceWidget: React.FunctionComponent<
  DownloadSourceWidgetType & WidgetDefaultProps
> = ({ id, className, uri, name, alt_text }) => {
  return (
    <a
      id={id}
      href={uri}
      title={alt_text}
      className={`downloadSource btn btn_block${
        className ? ' ' + className : ''
      }`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-download"
        >
          <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" />
        </svg>
        {name}
      </span>
    </a>
  );
};

export default DownloadSourceWidget;
