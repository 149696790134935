import React from 'react';
import { VideoMeta } from '../../parser';
import Image from './image';
import Lightbox from './lightbox';
import VimeoType from '@vimeo/player';

let Vimeo: any = null;

if (typeof window !== 'undefined') {
  import('@vimeo/player').then((VimeoModule) => {
    Vimeo = VimeoModule.default || VimeoModule;
  });
}

interface Props {
  video: VideoMeta;
  activeTag: string;
}

const VimeoCard: React.FunctionComponent<Props> = ({ video, activeTag }) => {
  const [duration, setDuration] = React.useState('0:00');
  const [playVideo, setPlayVideo] = React.useState(false);
  const vimeoIframe = React.useRef<HTMLIFrameElement | null>(null);
  const videoBtn = React.useRef<HTMLButtonElement | null>(null);
  const [linkCopied, setLinkCopied] = React.useState(false);

  const formatDate = (date: number) => {
    const d = new Date(date);
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();

    return [month, day, year].join('/');
  };

  const handleLinkCopy = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setLinkCopied(true);

    const url = window.location.origin;
    const newUrl = `${url}/our-insights/video/${video.slug}`;
    navigator.clipboard.writeText(newUrl);

    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  React.useEffect(() => {
    let plyr = null as VimeoType | null;

    if (vimeoIframe.current && Vimeo && typeof Vimeo === 'function') {
      const player = new Vimeo(vimeoIframe.current);
      plyr = player;

      if (
        videoBtn.current &&
        videoBtn.current.dataset.duration &&
        videoBtn.current.dataset.duration !== '0:00'
      ) {
        setDuration(videoBtn.current.dataset.duration);
      } else {
        player.getDuration().then((dur: number) => {
          const minutes = Math.floor(dur / 60);
          const seconds = Math.floor(dur % 60);
          setDuration(`${minutes}:${seconds}`);
        });
      }
    }

    return () => {
      plyr?.destroy();
    };
  }, [activeTag]);

  return (
    <>
      <button
        ref={videoBtn}
        data-duration={duration}
        className="videos--list-item-btn"
        onClick={() => {
          setPlayVideo(true);
        }}
      >
        <div className="videos--list-item-visualWrapper">
          <Image
            src={video.cover_image.uri}
            alt={video.cover_image.alt_text}
            minWidth={1200}
            className="videos--list-item-visual"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
          >
            <circle cx="12" cy="12" r="10" fill="#fff" stroke="#fff" />
            <path fill="#000" stroke="#000" d="m10 8 6 4-6 4V8z" />
          </svg>
          <div
            className={`videos--list-item-copy ${linkCopied ? 'copied' : ''}`}
            onClick={handleLinkCopy}
          >
            <svg viewBox="0 0 24 24" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.34412 13.2845C5.76446 13.8641 5.43882 14.6503 5.43882 15.4701C5.43882 16.2898 5.76446 17.076 6.34412 17.6557C6.92378 18.2353 7.70997 18.561 8.52972 18.561C8.93563 18.561 9.33756 18.481 9.71256 18.3257C10.0876 18.1704 10.4283 17.9427 10.7153 17.6557L12.451 15.92C12.8415 15.5295 13.4746 15.5295 13.8652 15.92C14.2557 16.3106 14.2557 16.9437 13.8652 17.3343L12.1295 19.0699C11.6568 19.5426 11.0956 19.9176 10.4779 20.1734C9.86027 20.4293 9.19827 20.561 8.52972 20.561C7.17953 20.561 5.88464 20.0246 4.92991 19.0699C3.97518 18.1151 3.43882 16.8203 3.43882 15.4701C3.43882 14.1199 3.97518 12.825 4.92991 11.8702L6.66553 10.1346C7.05606 9.7441 7.68922 9.74409 8.07975 10.1346C8.47027 10.5251 8.47027 11.1583 8.07975 11.5488L6.34412 13.2845Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.6577 10.7131C18.2374 10.1334 18.563 9.34723 18.563 8.52747C18.563 7.70771 18.2374 6.92152 17.6577 6.34187C17.0781 5.76221 16.2919 5.43656 15.4721 5.43656C15.0662 5.43656 14.6643 5.51651 14.2893 5.67184C13.9143 5.82717 13.5736 6.05485 13.2865 6.34186L11.5509 8.07749C11.1604 8.46801 10.5272 8.46801 10.1367 8.07749C9.74617 7.68697 9.74617 7.0538 10.1367 6.66328L11.8723 4.92765C12.3451 4.45492 12.9063 4.07992 13.5239 3.82408C14.1416 3.56824 14.8036 3.43656 15.4721 3.43656C16.8223 3.43656 18.1172 3.97292 19.072 4.92765C20.0267 5.88238 20.563 7.17728 20.563 8.52747C20.563 9.87766 20.0267 11.1726 19.072 12.1273L17.3363 13.8629C16.9458 14.2534 16.3126 14.2534 15.9221 13.8629C15.5316 13.4724 15.5316 12.8392 15.9221 12.4487L17.6577 10.7131Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.97979 15.0199C8.58927 14.6294 8.58927 13.9962 8.97979 13.6057L13.6081 8.97736C13.9986 8.58683 14.6318 8.58683 15.0223 8.97736C15.4129 9.36788 15.4129 10.001 15.0223 10.3916L10.394 15.0199C10.0035 15.4104 9.37031 15.4104 8.97979 15.0199Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <div className="videos--list-item-info">
          <h3 className="videos--list-item-info-title">{video.title}</h3>
          <div className="videos--list-item-info-time">
            <span className="videos--list-item-info-date">
              {formatDate(video.date)}
            </span>
            <span>|</span>
            <span className="videos--list-item-info-duration">{duration}</span>
          </div>
        </div>
      </button>
      <iframe
        src={video.video_url}
        frameBorder={0}
        ref={vimeoIframe}
        className="sr-only"
      />
      {playVideo && (
        <Lightbox
          show={true}
          item={{
            count: 1,
            inView: {
              index: 0,
              alt: '',
              src: video.video_url + '&autoplay=1',
              type: 'vimeo',
            },
          }}
          hidePagination={true}
          onClose={() => {
            setPlayVideo(false);
          }}
        />
      )}
    </>
  );
};

export default VimeoCard;
