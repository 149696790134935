import React from 'react';
import {
  AsideRichTextWidget as AsideRichTextWidgetType,
  WidgetDefaultProps,
} from '../../types';

const AsideRichTextWidget: React.FunctionComponent<
  AsideRichTextWidgetType & WidgetDefaultProps
> = ({ content, className }) => {
  return (
    <aside className={`asideRichText ${className || ''}`}>
      <div
        className="asideRichText--content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </aside>
  );
};

export default AsideRichTextWidget;
