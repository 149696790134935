import { Link } from 'gatsby';
import React from 'react';
import { Icon, Image } from '..';
import { CrimeIndexContentWidgetType, WidgetDefaultProps } from '../../types';

const CrimeIndexContentWidget: React.FunctionComponent<
  CrimeIndexContentWidgetType & WidgetDefaultProps
> = ({ className, content, image, icons_list, action, icons_list_title }) => {
  return (
    <div className={`crimeIndexContent ${className ? className : ''}`}>
      <div className="wrapper">
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="crimeIndexContent--content"
        />
        <Image
          src={image.uri}
          alt={image.alt_text}
          minWidth={1200}
          className="crimeIndexContent--image"
        />
        {icons_list_title && (
          <h2 className="crimeIndexContent--iconsList-title">
            {icons_list_title}
          </h2>
        )}
        {icons_list.length > 0 && (
          <ul className="crimeIndexContent--iconsList">
            {icons_list.map((item, index) => {
              return (
                <li key={index} className="crimeIndexContent--iconsList-item">
                  <div className="crimeIndexContent--iconsList-item-icon">
                    <Icon alt_text={item.icon.alt_text} uri={item.icon.uri} />
                  </div>
                  <div
                    className="crimeIndexContent--iconsList-item-title"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  {item.text && (
                    <div
                      className="crimeIndexContent--iconsList-item-text"
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        )}
        {action.link_to.trim() && (
          <Link
            to={action.link_to}
            className="crimeIndexContent--action btn btn_block btn_center"
          >
            <span>{action.label}</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CrimeIndexContentWidget;
