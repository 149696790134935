interface ImageServicePrototype {
  closest(width: number): number;
  checkWebP(): boolean;
  parsable(src: string): boolean;
  toSmallest(src: string, maxWidth: number, minWidth?: number): string;
}

function imageService(): ImageServicePrototype {
  const widths = [350, 600, 1200, 1920];
  let isWebPSupported: boolean;
  return {
    closest(width) {
      let delta = 100000;
      let bestI = 0;
      for (let i = 0; i < widths.length; i = i + 1) {
        let d = width - widths[i];
        d = d < 0 ? -d : d;
        if (d < delta) {
          delta = d;
          bestI = i;
        }
      }
      return widths[bestI];
    },
    checkWebP() {
      const elem = document.createElement('canvas');
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!(elem.getContext && elem.getContext('2d'))) {
        isWebPSupported =
          elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      } else {
        isWebPSupported = false;
      }
      return isWebPSupported;
    },
    parsable(ext) {
      return ['jpg', 'jpeg', 'png', 'webp'].includes(ext) ? true : false;
    },
    toSmallest(src, maxWidth, minWidth?) {
      const srcParts = src.split('.');
      const name = srcParts.slice(0, srcParts.length - 1).join('.');
      let ext = srcParts[srcParts.length - 1];
      if (this.parsable(ext)) {
        if (typeof document !== 'undefined' && this.checkWebP()) {
          ext = 'webp';
        }
        const width = this.closest(maxWidth);
        return `${name}-${
          minWidth ? (width < minWidth ? this.closest(minWidth) : width) : width
        }.${ext}`;
      }
      return src;
    },
  };
}

export const ImageService = imageService();
