import React from 'react';
import {
  CTALargeWidget as CTALargeWidgetType,
  WidgetDefaultProps,
} from '../../types';
import { Link } from 'gatsby';
import { Heading, Image } from '../global';

const CTALargeWidget: React.FunctionComponent<
  CTALargeWidgetType & WidgetDefaultProps
> = ({
  heading_block,
  className,
  call_to_action,
  background_image,
  background_image_alt_text,
}) => {
  return (
    <div
      className={`cta ${className ? className : ''} ${
        background_image ? 'cta_dark' : ''
      }`}
    >
      {background_image && (
        <Image
          minWidth={1200}
          className="cta--image"
          src={background_image}
          alt={background_image_alt_text}
        />
      )}
      {heading_block.background_color && (
        <div
          className="cta--overlay"
          style={{ backgroundColor: heading_block.background_color }}
        />
      )}
      <div className="wrapper">
        <Heading
          level={
            heading_block.headline_level.selected
              ? heading_block.headline_level.selected
              : ''
          }
          className="mt-0 cta--title"
        >
          {heading_block.headline}
        </Heading>
        {heading_block.description && (
          <div
            className="cta--description"
            dangerouslySetInnerHTML={{ __html: heading_block.description }}
          />
        )}
        {call_to_action.download_uri ? (
          <a
            href={call_to_action.download_uri}
            target="_blank"
            rel="noopener noreferrer"
            className={`btn btn_block ${background_image ? 'darkBg' : ''}`}
          >
            <span>{call_to_action.label}</span>
          </a>
        ) : (
          <Link
            className={`btn btn_block ${background_image ? 'darkBg' : ''}`}
            to={call_to_action.link_to}
          >
            <span>{call_to_action.label}</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CTALargeWidget;
