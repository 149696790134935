export const ISOLangs: {
  [key: string]: {
    name: string;
    nativeName: string;
  };
} = {
  en: {
    name: 'English',
    nativeName: 'English',
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch',
  },
  nl: {
    name: 'Dutch',
    nativeName: 'Nederlands',
  },
  pl: {
    name: 'Polish',
    nativeName: 'Polski',
  },
  sv: {
    name: 'Swedish',
    nativeName: 'Svenska',
  },
};
