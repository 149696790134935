import React from 'react';
import { PeopleListWidgetType, WidgetDefaultProps } from '../../types';
import { Image } from '../global';

const PeopleListWidget: React.FunctionComponent<
  PeopleListWidgetType & WidgetDefaultProps
> = ({ id, className, title, list }) => {
  return (
    <div id={id} className={`peopleListWidget ${className ? className : ''}`}>
      <h2 className="peopleListWidget--title">{title}</h2>
      <div className="peopleListWidget--list">
        {list.map((e, index) => {
          return (
            <div className="peopleListWidget--list-item" key={index}>
              <div className="peopleListWidget--list-item-imageWrapper">
                <Image
                  src={e.avatar.uri}
                  alt={e.avatar.alt_text}
                  className="peopleListWidget--list-item-image"
                />
              </div>
              <div className="peopleListWidget--list-item-text">
                <div className="peopleListWidget--list-item-name">{e.name}</div>
                <div className="peopleListWidget--list-item-role">{e.role}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PeopleListWidget;
