/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  AccordionWidget as AccordionWidgetType,
  DownloadSourceWidget as DownloadSourceWidgetType,
  HeadingWithBackgroundWidget as HeadingWithBackgroundWidgetType,
  ImageFloatWidget as ImageFloatWidgetType,
  MediaWidget as MediaWidgetType,
  VideoWidget as VideoWidgetType,
  WidgetDefaultProps,
  EntryContentParsedItem,
  AddressListWidget as AddressListWidgetType,
  DoubleBannersWidget as DoubleBannersWidgetType,
  CTALargeWidget as CTALargeWidgetType,
  MetaTagsWidget as MetaTagsWidgetType,
  SourcesWidget as SourcesWidgetType,
  ButtonWidgetType,
  PeopleListWidgetType,
  ColumnsWithIconsWidgetType,
  ContentWithVisualWidgetType,
  RelatedWidgetType,
  TagsWidgetType,
  StickyButtonsWidgetType,
  CTAMediumWidgetType,
  CrimeIndexContentWidgetType,
  CrimeIndexMethodologyWidgetType,
  HubspotWidgetType,
  HistoryTimelineWidget as HistoryTimelineWidgetType,
  TableWidgetType,
  ReferenceWidgetType,
  QuoteTextWidgetType,
  BlockquoteWidget as BlockquoteWidgetType,
  IconsTextListWidgetType,
} from '../../types';

import Accordion from './accordion';
import DownloadSourceWidget from './download-source';
import HeadingWithBackgroundWidget from './heading-with-background';
import HorizontalLineWidget from './horizontal-line';
import ImageFloatWidget from './image-float';
import MediaWidget from './media';
import VideoWidget from './video';
import AddressListWidget from './address-list';
import DoubleBannersWidget from './double-banners';
import CTALargeWidget from './cta-large';
import MetaTagsWidget from './meta-tags';
import SourcesWidget from './sources';
import ButtonWidget from './button';
import ColumnsWithIconsWidget from './columns-with-icons';
import PeopleListWidget from './people-list';
import ContentWithVisualWidget from './content-with-visual';
import TagsWidget from './tags';
import RelatedWidget from './related';
import StickyButtonsWidget from './sticky-buttons';
import CTAMediumWidget from './cta_medium';
import CrimeIndexContentWidget from './crime-index-content';
import CrimeIndexMethodologyWidget from './crime-index-methodology';
import HubspotFormWidget from './hubspot-form';
import HistoryTimelineWidget from './history-timeline';
import TableWidget from './table';
import ReferenceWidget from './reference';
import QuoteTextWidget from './quote-text';
import BlockquoteWidget from './blockquote';
import IconsTextListWidget from './icons-text-list';

interface Props extends WidgetDefaultProps {
  widget: EntryContentParsedItem;
  onImageClick?: (src: string) => void;
  minImageWidth?: number;
}

const WidgetManager: React.FunctionComponent<Props> = ({
  id,
  className,
  widget,
  minImageWidth,
  onImageClick,
  children,
}) => {
  switch (widget.name) {
    case 'interested_in': {
      const value: RelatedWidgetType = widget.value as any;
      return (
        <RelatedWidget
          title={value.title}
          items={value.items}
          slug_prefix={value.slug_prefix}
        />
      );
    }
    case 'tags': {
      const value: TagsWidgetType = widget.value as any;
      return <TagsWidget items={value.items} />;
    }
    case 'accordion': {
      const value: AccordionWidgetType = widget.value as any;
      return (
        <Accordion id={id} className={className} items={value.items}>
          {children}
        </Accordion>
      );
    }
    case 'download_source': {
      const value: DownloadSourceWidgetType = widget.value as any;
      return (
        <DownloadSourceWidget
          id={id}
          className={className}
          name={value.name}
          uri={value.uri}
          alt_text={value.alt_text}
        >
          {children}
        </DownloadSourceWidget>
      );
    }
    case 'heading_with_background': {
      const value: HeadingWithBackgroundWidgetType = widget.value as any;
      return (
        <HeadingWithBackgroundWidget
          id={id ? id : value.id}
          className={className}
          color={value.color}
          heading_level={value.heading_level}
          text={value.text}
        >
          {children}
        </HeadingWithBackgroundWidget>
      );
    }
    case 'horizontal_line': {
      return (
        <HorizontalLineWidget id={id} className={className}>
          {children}
        </HorizontalLineWidget>
      );
    }
    case 'image_float': {
      const value: ImageFloatWidgetType = widget.value as any;
      return (
        <ImageFloatWidget
          id={id}
          className={className}
          onImageClick={onImageClick}
          float={value.float}
          image={value.image}
          image_alt_text={value.image_alt_text}
        >
          {children}
        </ImageFloatWidget>
      );
    }
    case 'video': {
      const value: VideoWidgetType = widget.value as any;
      return (
        <VideoWidget
          video={value.video}
          youtube_url={value.youtube_url}
          vimeo_url={value.vimeo_url}
        />
      );
    }
    case 'migration_media':
    case 'media': {
      const value: MediaWidgetType = widget.value as any;
      return (
        <MediaWidget
          media={value.media}
          caption={value.caption}
          alt_text={value.alt_text}
          onImageClick={onImageClick}
          minImageWidth={minImageWidth}
        />
      );
    }
    case 'address_list': {
      const value: AddressListWidgetType = widget.value as any;
      return <AddressListWidget items={value.items} />;
    }
    case 'double_banners': {
      const value: DoubleBannersWidgetType = widget.value as any;
      return <DoubleBannersWidget items={value.items} />;
    }
    case 'cta_large': {
      const value: CTALargeWidgetType = widget.value as any;
      return (
        <CTALargeWidget
          heading_block={value.heading_block}
          call_to_action={value.call_to_action}
          background_image={value.background_image}
          background_image_alt_text={value.background_image_alt_text}
        />
      );
    }
    case 'meta_tags': {
      const value: MetaTagsWidgetType = widget.value as any;
      return (
        <MetaTagsWidget
          title={value.title}
          description={value.description}
          image={value.image}
        />
      );
    }
    case 'content_with_visual': {
      const value: ContentWithVisualWidgetType = widget.value as any;
      return (
        <ContentWithVisualWidget
          background_color={value.background_color}
          image={value.image}
          content={value.content}
          visual_position={value.visual_position}
        />
      );
    }
    case 'sources': {
      const value: SourcesWidgetType = widget.value as any;
      return <SourcesWidget content={value.content} />;
    }
    case 'aside_rich_text': {
      return <></>;
    }
    case 'button': {
      const value: ButtonWidgetType = widget.value as any;
      return (
        <ButtonWidget
          label={value.label}
          url={value.url}
          position={value.position}
          dark={value.dark}
          theme={value.theme}
        />
      );
    }
    case 'columns_with_icons': {
      const value: ColumnsWithIconsWidgetType = widget.value as any;
      return <ColumnsWithIconsWidget items={value.items} />;
    }
    case 'people_list': {
      const value: PeopleListWidgetType = widget.value as any;
      return <PeopleListWidget title={value.title} list={value.list} />;
    }
    case 'sticky_buttons': {
      const value: StickyButtonsWidgetType = widget.value as any;
      return <StickyButtonsWidget buttons={value.buttons} />;
    }
    case 'cta_medium': {
      const value: CTAMediumWidgetType = widget.value as any;
      return (
        <CTAMediumWidget
          heading={value.heading}
          background={value.background}
          actions={value.actions}
        />
      );
    }
    case 'crime_index_content': {
      const value: CrimeIndexContentWidgetType = widget.value as any;
      return (
        <CrimeIndexContentWidget
          content={value.content}
          image={value.image}
          icons_list={value.icons_list}
          icons_list_title={value.icons_list_title}
          action={value.action}
        />
      );
    }
    case 'icons_text_list': {
      const value: IconsTextListWidgetType = widget.value as any;
      return <IconsTextListWidget title={value.title} list={value.list} />;
    }
    case 'crime_index_methodology': {
      const value: CrimeIndexMethodologyWidgetType = widget.value as any;
      return (
        <CrimeIndexMethodologyWidget
          content={value.content}
          image={value.image}
        />
      );
    }
    case 'hubspot_form': {
      const value: HubspotWidgetType = widget.value as any;
      return (
        <HubspotFormWidget
          form_id={value.form_id}
          dark={value.dark}
          className={value.className}
          popup_button_label={value.popup_button_label}
        />
      );
    }
    case 'history_timeline': {
      const value: HistoryTimelineWidgetType = widget.value as any;
      return <HistoryTimelineWidget items={value.items} />;
    }
    case 'quote_text': {
      const value: QuoteTextWidgetType = widget.value as any;
      return <QuoteTextWidget content={value.content} center={value.center} />;
    }
    case 'table': {
      const value: TableWidgetType = widget.value as any;
      return (
        <TableWidget columns={value.columns} footer_link={value.footer_link} />
      );
    }
    case 'reference': {
      const value: ReferenceWidgetType = widget.value as any;
      return <ReferenceWidget reference={value.reference} />;
    }
    case 'blockquote': {
      const value: BlockquoteWidgetType = widget.value as any;
      return <BlockquoteWidget {...value} />;
    }
    default: {
      console.error(
        `Widget with name "${widget.name}" is not handled. \n\n${JSON.stringify(
          widget,
          null,
          '  ',
        )}`,
      );
      return <></>;
    }
  }
};

export default WidgetManager;
