export interface FooterItem {
  label: string;
  items: Array<{
    label: string;
    to: string;
  }>;
}

export const FooterItems: FooterItem[] = [
  {
    label: 'Offerings',
    items: [
      {
        label: 'Embedded SMEs',
        to: '/services/embedded-smes',
      },
      {
        label: 'Protection',
        to: '/services/protection',
      },
      {
        label: 'Investigations',
        to: '/services/investigations',
      },
      {
        label: 'Consulting',
        to: '/services/advisory',
      },
    ],
  },
  {
    label: 'Insights',
    items: [
      {
        label: 'Overview',
        to: '/our-insights',
      },
      {
        label: 'Briefings',
        to: '/our-insights/analysis',
      },
      {
        label: 'Blog',
        to: '/our-insights/blog',
      },
      {
        label: 'Video',
        to: '/our-insights/video',
      },
      {
        label: 'Risk Snapshots',
        to: '/our-insights/risk-snapshots',
      },
    ],
  },
  {
    label: 'About Us',
    items: [
      {
        label: 'Our Approach',
        to: '/our-approach',
      },
      {
        label: 'Our History',
        to: '/our-story/history',
      },
      {
        label: 'Our Story',
        to: '/our-story',
      },
      {
        label: 'Our Values',
        to: '/our-story/values',
      },
      {
        label: 'Press Room',
        to: '/press-room',
      },
    ],
  },
  {
    label: 'Join Us',
    items: [
      {
        label: 'Careers',
        to: '/careers/current-openings',
      },
      {
        label: 'Vendor Program',
        to: '/gam',
      },
    ],
  },
  {
    label: 'Portals',
    items: [
      {
        label: 'Risk Portal',
        to: 'https://portal.pinkerton.com/main',
      },
      {
        label: 'GAM Portal',
        to: 'https://global-nexus2.pinkerton.com/login/vendor-registration',
      },
    ],
  },
  {
    label: 'Connect',
    items: [
      {
        label: 'Legal',
        to: '/legal',
      },
      {
        label: 'Contact Us',
        to: '/contact-us',
      },
      {
        label: 'Find Your Pinkerton',
        to: '/find-your-pinkerton',
      },
    ],
  },
];
