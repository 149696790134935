import React from 'react';
import {
  BlockquoteWidget as BlockquoteWidgetType,
  WidgetDefaultProps,
} from '../../types';

const BlockquoteWidget: React.FunctionComponent<
  BlockquoteWidgetType & WidgetDefaultProps
> = ({ id, className, heading, content, caption }) => {
  return (
    <div id={id} className={`blockquoteWidget ${className || ''}`}>
      {heading && <h4 className="blockquoteWidget--heading">{heading}</h4>}
      <blockquote
        className="blockquoteWidget--content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {caption && (
        <caption className="blockquoteWidget--caption">{caption}</caption>
      )}
    </div>
  );
};

export default BlockquoteWidget;
