import React from 'react';
import { RelatedItemOutput } from '../../../parser/util';
import { ResImg } from '../../global';

interface Props {
  title: string;
  items: RelatedItemOutput[];
}

const CrimeIndexRelatedByTag: React.FunctionComponent<Props> = ({
  title,
  items,
}) => {
  return (
    <section className="wrapper">
      <div className="postList">
        <h2 className="postList--title">{title}</h2>
        <div className="postList--inner">
          {items.map((item, itemIndex) => (
            <article key={itemIndex} className="postList--item">
              <a href={item.slug} target="_blank" rel="noopener noreferrer">
                <div className="postList--item-cover">
                  <ResImg
                    alt={item.title}
                    src={item.image.uri}
                    minWidth={1200}
                    width={1200}
                    height={600}
                  />
                </div>
                <div className="postList--item-overlay"></div>
                <div className="postList--item-inner">
                  <h3 className="postList--item-title">{item.title}</h3>
                  <p className="postList--item-description">
                    {item.description}
                  </p>
                </div>
              </a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CrimeIndexRelatedByTag;
