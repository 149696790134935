import { Link } from 'gatsby';
import React from 'react';
import { RedirectBoxProps } from '../../types';

const RedirectBox: React.FunctionComponent<RedirectBoxProps> = ({
  title,
  description,
  path,
}) => {
  return (
    <div className="redirectBox">
      <Link className="btn btn_block btn_grey" to={path}>
        <h5 className="redirectBox--title">{title}</h5>
        <p className="redirectBox--description">{description}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-right"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </Link>
    </div>
  );
};

export default RedirectBox;
