import React from 'react';
import {
  DoubleBannersWidget as DoubleBannersWidgetType,
  WidgetDefaultProps,
} from '../../types';
import * as uuid from 'uuid';
import { Link } from 'gatsby';
import { ImageService } from '../../services';

const DoubleBannersWidget: React.FunctionComponent<
  DoubleBannersWidgetType & WidgetDefaultProps
> = ({ className, items }) => {
  return (
    <div className={`doubleBanners ${className ? className : ''}`}>
      {items.map((item) => {
        return (
          <section
            className={`${item.dark ? 'dark' : 'light'}`}
            key={uuid.v4()}
            style={{
              backgroundImage: `url(${ImageService.toSmallest(
                item.background_image,
                1920,
              )})`,
            }}
          >
            <div className="doubleBanners--inner">
              <div className="doubleBanners--overlay"></div>
              {item.subtitle && (
                <div className="doubleBanners--subtitle">{item.subtitle}</div>
              )}
              <div className="doubleBanners--title">{item.title}</div>
              <Link
                className="btn btn_block darkBg"
                to={item.call_to_action.link_to}
              >
                <span>{item.call_to_action.label}</span>
              </Link>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default DoubleBannersWidget;
