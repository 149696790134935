import { Link, navigate } from 'gatsby';
import React from 'react';
import { HeaderItems as NavItems, HeaderSocial } from '../../data';
import { Image } from '../global';

const Nav: React.FunctionComponent = () => {
  const searchInput = React.useRef<HTMLInputElement>(null);

  const search = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!searchInput.current) {
      return;
    }
    searchInput.current.value &&
      navigate(
        `/search/?search=${encodeURIComponent(searchInput.current.value)}`,
      );
  };

  return (
    <nav className="nav" data-nav>
      <ul className="nav--list">
        {NavItems.map((navItem, navItemIndex) => {
          return (
            <li key={navItemIndex} className="nav--list-item">
              <button
                className="nav--list-item-toggler"
                onClick={(event) => {
                  const target = event.currentTarget as HTMLButtonElement;

                  target.classList.toggle('active');
                }}
              >
                <span>{navItem.name}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 5v14" />
                  <path d="M5 12h14" />
                </svg>
              </button>
              <div className="nav--list-item-subNav">
                {navItem.head && (
                  <div className="nav--list-item-subNav-head">
                    <div className="nav--list-item-subNav-item">
                      {navItem.head.label && (
                        <div className="nav--list-item-subNav-item-label nav--list-item-subNav-item-label_link">
                          {navItem.head.label}
                        </div>
                      )}
                      <ul className="nav--list-item-subNav-item-list">
                        {navItem.head.items
                          .filter((e) => !e.social)
                          .map((navSubItem, subNavItemIndex) => {
                            return (
                              <li
                                className="nav--list-item-subNav-item-list-item"
                                key={subNavItemIndex}
                              >
                                {navSubItem.label &&
                                  (navSubItem.url ? (
                                    navSubItem.url.startsWith('/') ? (
                                      <Link
                                        to={navSubItem.url}
                                        className="nav--list-item-subNav-item-list-item-label nav--list-item-subNav-item-list-item-label_link"
                                      >
                                        <span>{navSubItem.label}</span>
                                      </Link>
                                    ) : (
                                      <a
                                        href={navSubItem.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="nav--list-item-subNav-item-list-item-label nav--list-item-subNav-item-list-item-label_link"
                                      >
                                        <span>{navSubItem.label}</span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="1693.583 273.2 24.45 14.617"
                                        >
                                          <g
                                            fill="transparent"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            data-name="Group 3"
                                          >
                                            <path
                                              d="m1710.652 274.63 5.871 5.87-5.87 5.87"
                                              data-name="Path 1"
                                            />
                                            <path
                                              d="M1716.588 280.424H1695"
                                              data-name="Line 3"
                                            />
                                          </g>
                                        </svg>
                                      </a>
                                    )
                                  ) : (
                                    <div className="nav--list-item-subNav-item-list-item-label">
                                      {navSubItem.label}
                                    </div>
                                  ))}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                )}
                {navItem.table?.map((item, index) => {
                  return (
                    <div className="nav--list-item-subNav-item" key={index}>
                      {item.label &&
                        (item.url ? (
                          <Link
                            to={item.url}
                            className="nav--list-item-subNav-item-label nav--list-item-subNav-item-label_link"
                          >
                            {item.label}
                          </Link>
                        ) : (
                          <div className="nav--list-item-subNav-item-label">
                            {item.label}
                          </div>
                        ))}
                      <ul className="nav--list-item-subNav-item-list">
                        {item.items.map((navSubItem, subNavItemIndex) => {
                          return (
                            <li
                              className="nav--list-item-subNav-item-list-item"
                              key={subNavItemIndex}
                            >
                              {navSubItem.label &&
                                (navSubItem.url ? (
                                  navSubItem.url.startsWith('/') ? (
                                    <Link
                                      to={navSubItem.url}
                                      className="nav--list-item-subNav-item-list-item-label nav--list-item-subNav-item-list-item-label_link"
                                    >
                                      <span>{navSubItem.label}</span>
                                    </Link>
                                  ) : (
                                    <a
                                      href={navSubItem.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="nav--list-item-subNav-item-list-item-label nav--list-item-subNav-item-list-item-label_link"
                                    >
                                      <span>{navSubItem.label}</span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="1693.583 273.2 24.45 14.617"
                                      >
                                        <g
                                          fill="transparent"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          data-name="Group 3"
                                        >
                                          <path
                                            d="m1710.652 274.63 5.871 5.87-5.87 5.87"
                                            data-name="Path 1"
                                          />
                                          <path
                                            d="M1716.588 280.424H1695"
                                            data-name="Line 3"
                                          />
                                        </g>
                                      </svg>
                                    </a>
                                  )
                                ) : (
                                  <div className="nav--list-item-subNav-nested">
                                    <div className="nav--list-item-subNav-item-label">
                                      {navSubItem.label}
                                    </div>
                                    {navSubItem.items &&
                                      navSubItem.items.length > 0 && (
                                        <ul className="nav--list-item-subNav-item-list">
                                          {navSubItem.items.map(
                                            (
                                              navSubItemSub,
                                              subNavItemSubIndex,
                                            ) => {
                                              return (
                                                <li
                                                  key={subNavItemSubIndex}
                                                  className="nav--list-item-subNav-item-list-item"
                                                >
                                                  <Link
                                                    to={navSubItemSub.url}
                                                    className="nav--list-item-subNav-item-list-item-label nav--list-item-subNav-item-list-item-label_link"
                                                  >
                                                    <span>
                                                      {navSubItemSub.label}
                                                    </span>
                                                  </Link>
                                                </li>
                                              );
                                            },
                                          )}
                                        </ul>
                                      )}
                                  </div>
                                ))}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </li>
          );
        })}
        <li className="nav--list-item nav--list-item_contact">
          <Link to="/careers/current-openings">Careers</Link>
        </li>
      </ul>
      <div className="nav--social nav--list-item-subNav-item-social">
        {HeaderSocial.map((navItemSocialLink, navItemSocialLinkIndex) => {
          return (
            <a
              href={navItemSocialLink.url}
              target="_blank"
              rel="noopener noreferrer"
              key={navItemSocialLinkIndex}
            >
              <Image src={navItemSocialLink.icon} alt="Icon" />
            </a>
          );
        })}
      </div>
      <form
        onSubmit={(event) => {
          search(event);
        }}
        className="nav--form"
      >
        <label>
          <span className="sr-only">Search:</span>
          <input
            type="text"
            name="search"
            placeholder="TYPE TO SEARCH"
            ref={searchInput}
          ></input>
        </label>
        <button type="submit">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="904.567 733.667 25.217 26.267"
            >
              <g
                fill="transparent"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                data-name="Group 1"
              >
                <path
                  strokeWidth="1.789"
                  d="M911.5 743.405a8.5 8.5 0 1 1 0 .09z"
                  data-name="Ellipse 1"
                />
                <path strokeWidth="2" d="m914 750.5-8 8" data-name="Line 1" />
              </g>
            </svg>
          </span>
        </button>
      </form>
    </nav>
  );
};

export default Nav;
