import React from 'react';

interface Props {
  formId: string;
  className?: string;
}

const HubspotForm: React.FunctionComponent<Props> = ({
  formId,
  className,
}: Props) => {
  const [randomFormPrefix, setRandomFormPrefix] = React.useState<string>('');

  React.useEffect(() => {
    const interval = setInterval(() => {
      if ((window as any).hbspt) {
        clearInterval(interval);
        const prefix = Math.random().toString(36).substr(2, 9);
        setRandomFormPrefix(prefix);
        (window as any).hbspt.forms.create({
          region: 'na1',
          portalId: '21140208',
          formId: formId,
          target: `#form-id-${prefix + formId}`,
        });
      }
    }, 200);
  }, [formId]);

  return randomFormPrefix ? (
    <div
      id={`form-id-${randomFormPrefix + formId}`}
      className={`hubspotForm ${className ? className : ''}`}
    />
  ) : (
    <></>
  );
};

export default HubspotForm;
