import { Link } from 'gatsby';
import React from 'react';

const CookieConsent: React.FunctionComponent = () => {
  const [show, setShow] = React.useState(false);

  function setConsent() {
    localStorage.setItem('pinkerton_cookie_consent', 'true');
    setShow(false);
  }
  React.useEffect(() => {
    const consent = localStorage.getItem('pinkerton_cookie_consent');
    if (!consent && consent !== 'true') {
      setShow(true);
    }
  }, []);
  return (
    <div className="cookieConsent">
      {show ? (
        <div className="cookieConsent--content">
          <p>
            This site uses cookies to provide you with a more responsive and
            personalized service. By using this site you agree to our use of
            cookies. Please{' '}
            <Link to="/privacy-policy">read our Privacy Policy</Link> for more
            information on the cookies we use and how to delete or block them.
          </p>
          <button onClick={setConsent}>Got it!</button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CookieConsent;
