import React from 'react';
import { HubspotForm } from '.';
import { AsideHubspotFormGroupType } from '../../parser';

const AsideHubspotForm: React.FunctionComponent<AsideHubspotFormGroupType> = ({
  title,
  form_id,
  dark,
  className,
}) => {
  return (
    <div className={`asideHubspotForm ${className || ''}`}>
      {title && <h2 className="asideHubspotForm--title">{title}</h2>}
      {form_id && (
        <HubspotForm
          formId={form_id}
          className={`asideHubspotForm--form ${dark ? 'darkBg' : ''}`}
        />
      )}
    </div>
  );
};

export default AsideHubspotForm;
