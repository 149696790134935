import React from 'react';
import {
  HistoryTimelineWidget as HistoryTimelineWidgetType,
  WidgetDefaultProps,
} from '../../types';
import { Image } from '../global';

const HistoryTimelineWidget: React.FunctionComponent<
  HistoryTimelineWidgetType & WidgetDefaultProps
> = ({ items, className }) => {
  return (
    <div className={`historyTimelineWidget ${className || ''}`}>
      <div className="wrapper">
        <ul className="historyTimelineWidget--list">
          {items.map((item, index) => {
            return (
              <li className="historyTimelineWidget--list-item" key={index}>
                {index % 2 !== 0 && (
                  <div className="historyTimelineWidget--list-item-empty" />
                )}
                <div className="historyTimelineWidget--list-item-content">
                  <div className="historyTimelineWidget--list-item-image">
                    <Image
                      src={item.image.uri}
                      alt={item.image.alt_text}
                      minWidth={800}
                      width={600}
                      height={600}
                    />
                  </div>
                  <div className="historyTimelineWidget--list-item-text">
                    <h2 className="historyTimelineWidget--list-item-text-title">
                      {item.year}
                    </h2>
                    <p className="historyTimelineWidget--list-item-text-description">
                      {item.description}
                    </p>
                  </div>
                </div>
                {index % 2 === 0 && (
                  <div className="historyTimelineWidget--list-item-empty" />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default HistoryTimelineWidget;
