import React from 'react';
import Image from './image';

interface Props {
  src: string;
  alt: string;
  className?: string;
  style?: React.HTMLAttributes<HTMLImageElement>;
  minWidth?: number;
  width?: number;
  height?: number;
  noOpacityTransition?: boolean;
}

const ResImg: React.FunctionComponent<Props> = ({
  src,
  alt,
  className,
  style,
  minWidth,
  width,
  height,
  noOpacityTransition,
}: Props) => {
  return (
    <div className={'resImg' + (className ? ' ' + className : '')}>
      <Image
        src={src}
        alt={alt}
        style={style}
        minWidth={minWidth}
        width={width}
        height={height}
        noOpacityTransition={noOpacityTransition}
      />
    </div>
  );
};

export default ResImg;
