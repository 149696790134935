import React from 'react';
import { LightboxProps } from '../../types';
import Image from './image';

const Lightbox: React.FunctionComponent<LightboxProps> = ({
  show,
  item,
  onClose,
  onNext,
  onPrevious,
  hidePagination,
}) => {
  let closeLatch = false;

  return (
    <div
      style={{ display: show ? 'block' : 'none' }}
      role="button"
      onClick={() => {
        if (onClose) {
          if (closeLatch) {
            closeLatch = false;
          } else {
            onClose();
          }
        }
      }}
      className="lightbox"
      aria-label="Close lightbox"
    >
      <div className="lightbox--content">
        <div className="lightbox--image">
          {onPrevious && (
            <button
              aria-label="Previous Image"
              onClick={() => {
                closeLatch = true;
                onPrevious();
              }}
              className="lightbox--btn lightbox--btn_previous"
            />
          )}
          {item.inView.type === 'img' ? (
            <Image
              key={item.inView.index}
              src={item.inView.src}
              alt={item.inView.alt}
            />
          ) : item.inView.type === 'vid' ? (
            <video
              playsInline={true}
              key={item.inView.index}
              muted={true}
              autoPlay={true}
            >
              <source src={item.inView.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <iframe src={item.inView.src} frameBorder={0} />
          )}
          {onNext && (
            <button
              aria-label="Next Image"
              onClick={() => {
                closeLatch = true;
                onNext();
              }}
              className="lightbox--btn lightbox--btn_next"
            />
          )}
        </div>
        {!hidePagination && (
          <div className="lightbox--details">
            {item.description && (
              <h5 className="lightbox--details-headline">{item.description}</h5>
            )}
            <p className="lightbox--pagination">
              Image {item.inView.index + 1} of {item.count}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Lightbox;
