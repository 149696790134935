import React from 'react';
import { WidgetDefaultProps } from '../../types';

const HorizontalLineWidget: React.FunctionComponent<WidgetDefaultProps> = ({
  id,
  className,
}) => {
  return <hr id={id} className={className} />;
};

export default HorizontalLineWidget;
