import React from 'react';
import {
  HeadingWithBackgroundWidget as HeadingWithBackgroundWidgetType,
  WidgetDefaultProps,
} from '../../types';
import { Heading } from '../global';

const HeadingWithBackgroundWidget: React.FunctionComponent<
  HeadingWithBackgroundWidgetType & WidgetDefaultProps
> = ({ id, className, color, heading_level, text }) => {
  return (
    <Heading
      id={id}
      className={
        `headingWithBackground ` +
        (color.selected ? color.selected.toLowerCase() : '') +
        `${className ? ' ' + className : ''}`
      }
      level={heading_level.selected ? heading_level.selected : 'undefined'}
    >
      {text}
    </Heading>
  );
};

export default HeadingWithBackgroundWidget;
