import React from 'react';
import { RelatedItemOutput } from '../../parser/util';
import { GeneralService } from '../../services';
import { EntryContentParsed, PropType } from '../../types';
import {
  RelatedWidget,
  SourcesWidget,
  TagsWidget,
  WidgetManager,
} from '../widgets';
import HubspotForm from './hubspot-form';

interface Props {
  id?: string;
  key?: string;
  className?: string;
  onImageClick?: (src: string) => void;
  date?: number;
  content: EntryContentParsed;
  tags?: string[];
  formId?: string;
  related?: {
    title: string;
    slug_prefix: string;
    items: RelatedItemOutput[];
  };
  fullWidth?: boolean;
}

const Article: React.FunctionComponent<Props> = ({
  id,
  key,
  className,
  onImageClick,
  date,
  content,
  children,
  tags,
  formId,
  related,
  fullWidth,
}) => {
  const datePublished = date
    ? GeneralService.millis.toDateStringArray(date)
    : undefined;

  React.useEffect(() => {
    document.querySelectorAll('.twoColSingle--main a').forEach((_link) => {
      const link = _link as HTMLLinkElement;
      if (
        link.href.startsWith('http://allan.pinkerton.com') || // STAGING
        link.href.startsWith('https://pinkerton.com') || // PRODUCTION
        link.href.startsWith('http://localhost:8000') || // DEV
        link.href.startsWith('/')
      ) {
        // INTERNAL
        if (!link.classList.contains('downloadSource')) {
          link.setAttribute('target', '_self');
        }
      } else {
        // EXTERNAL
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      }
    });
  }, []);

  return (
    <article
      id={id}
      key={key}
      className={`article ${!fullWidth ? 'twoColSingle--main' : ''} ${
        className ? className : ''
      }`}
    >
      {content
        .filter((e) => e.name !== 'sources')
        .map((contentItem, contentItemIndex) => {
          switch (contentItem.type) {
            case PropType.WIDGET: {
              return (
                <WidgetManager
                  onImageClick={onImageClick}
                  minImageWidth={1200}
                  key={'' + contentItemIndex}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  widget={contentItem}
                >
                  {children}
                </WidgetManager>
              );
            }
            default: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const value: string = contentItem.value as any;
              return (
                <div
                  key={contentItemIndex}
                  dangerouslySetInnerHTML={{ __html: value }}
                ></div>
              );
            }
          }
        })}
      {datePublished && (
        <div className="page--date">
          Published{' '}
          {`${datePublished[1]} ${datePublished[2]}, ${datePublished[0]}`}
        </div>
      )}
      {formId && <HubspotForm formId={formId} />}
      {content.find((e) => e.name === 'sources') && (
        <SourcesWidget
          content={
            (content.find((e) => e.name === 'sources')?.value as any).content
          }
        />
      )}
      {tags && tags.filter((e) => e).length > 0 && <TagsWidget items={tags} />}
      {related && related.items.length > 0 && (
        <RelatedWidget
          title={related.title}
          items={related.items}
          slug_prefix={related.slug_prefix}
        />
      )}
    </article>
  );
};

export default Article;
