import React from 'react';
import {
  VideoWidget as VideoWidgetType,
  WidgetDefaultProps,
} from '../../types';

const VideoWidget: React.FunctionComponent<
  VideoWidgetType & WidgetDefaultProps
> = ({ id, className, video, vimeo_url, youtube_url }) => {
  const ext = video.split('.')[video.split('.').length - 1];

  return video ? (
    <div
      id={id}
      className={`videoContainer${className ? ' ' + className : ''}`}
    >
      <video controls>
        <source src={video} type={`video/${ext}`}></source>
        <track default kind="captions" srcLang="en" />
        Sorry, your browser doesn{"'"}t support embedded videos.
      </video>
    </div>
  ) : youtube_url ? (
    <div
      id={id || 'video'}
      className={`youtubeVideo${className ? ' ' + className : ''}`}
    >
      <iframe
        title="youtube video"
        src={youtube_url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  ) : vimeo_url ? (
    <div
      id={id || 'video'}
      className={`vimeoVideo${className ? ' ' + className : ''}`}
    >
      <iframe
        title="vimeo video"
        src={vimeo_url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  ) : (
    <></>
  );
};

export default VideoWidget;
