import { navigate } from 'gatsby';

interface NavigateOptions<TState> {
  state?: TState;
  replace?: boolean;
}

export interface PathServicePrototype {
  set(path: string): void;
  get(): string;
  getQueries<T>(): T;
  navigate<T>(config: {
    to: string;
    options?: NavigateOptions<T>
  }): void;
}

function pathService(): PathServicePrototype {
  let path = '';
  return {
    set(_path) {
      path = '' + _path;
    },
    get() {
      return path;
    },
    getQueries<T>(): T {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const queries: any = {};
      const urlParts = ('' + window.location).split('?');
      if (urlParts.length === 2) {
        const queryString = urlParts[1];
        const queryStringParts = queryString.split('&');
        for (const i in queryStringParts) {
          const queryParts = queryStringParts[i].split('=');
          if (queryParts.length === 2) {
            try {
              queries[queryParts[0]] = decodeURIComponent(queryParts[1]);
            } catch (err) {
              console.error('Bad query: ' + queryParts[i]);
            }
          } else {
            console.error('Bad query: ' + queryParts[i]);
          }
        }
      }
      return queries;
    },
    navigate(config) {
      navigate(config.to, config.options);
    },
  };
}

export const PathService = pathService();
