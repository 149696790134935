import React from 'react';
import { Link } from 'gatsby';
import { StickyButtonsWidgetType, WidgetDefaultProps } from '../../types';

const StickyButtonsWidget: React.FunctionComponent<
  StickyButtonsWidgetType & WidgetDefaultProps
> = ({ className, buttons }) => {
  return (
    <div className={`stickyButtons ${className}`}>
      {buttons.slice(0, 2).map((button, index) => {
        return button.url.startsWith('/') ? (
          <Link
            to={button.url}
            key={index}
            className={`btn btn_block ${
              button.theme.selected === 'TRANSPARENT' ? 'btn_transparent' : ''
            }`}
          >
            <span>{button.label}</span>
          </Link>
        ) : (
          <a
            href={button.url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            className={`btn btn_block ${
              button.theme.selected === 'TRANSPARENT' ? 'btn_transparent' : ''
            }`}
          >
            <span>{button.label}</span>
          </a>
        );
      })}
    </div>
  );
};

export default StickyButtonsWidget;
