import React from 'react';
import { CTAMediumWidgetType, WidgetDefaultProps } from '../../types';
import { Link } from 'gatsby';
import { Heading } from '../global';
import { ImageService } from '../../services';

const CTAMediumWidget: React.FunctionComponent<
  CTAMediumWidgetType & WidgetDefaultProps
> = ({ className, heading, background, actions }) => {
  return (
    <div
      className={`cta ctaMedium cta_dark ${className ? className : ''}`}
      style={{
        backgroundImage: `url(${ImageService.toSmallest(
          background.uri,
          1600,
          1200,
        )})`,
      }}
    >
      {heading.background_color && (
        <div
          className="cta--overlay"
          style={{
            backgroundColor: heading.background_color,
            mixBlendMode: 'multiply',
          }}
        />
      )}
      <div className="wrapper">
        <Heading
          level={
            heading.headline_level.selected
              ? heading.headline_level.selected
              : ''
          }
          className="mt-0 cta--title"
        >
          {heading.headline}
        </Heading>
        {heading.description && (
          <div
            className="cta--description"
            dangerouslySetInnerHTML={{ __html: heading.description }}
          />
        )}
        {actions.length > 0 && (
          <div className="cta--actions">
            {actions.map((action, index) => {
              return action.url.startsWith('/') ? (
                <Link
                  to={action.url}
                  key={index}
                  className={`btn btn_block ${
                    action.theme.selected === 'TRANSPARENT'
                      ? 'btn_transparent darkBg'
                      : ''
                  }`}
                >
                  <span>{action.label}</span>
                </Link>
              ) : (
                <a
                  href={action.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className={`btn btn_block ${
                    action.theme.selected === 'TRANSPARENT'
                      ? 'btn_transparent darkBg'
                      : ''
                  }`}
                >
                  <span>{action.label}</span>
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CTAMediumWidget;
