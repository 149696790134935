import React from 'react';
import { QuoteTextWidgetType, WidgetDefaultProps } from '../../types';

const QuoteTextWidget: React.FunctionComponent<
  QuoteTextWidgetType & WidgetDefaultProps
> = ({ id, className, content, center }) => {
  return (
    <blockquote
      id={id}
      className={`quoteTextWidget ${center ? 'quoteTextWidget_center' : ''} ${
        className || ''
      }`}
    >
      <div className="quoteTextWidget--content">
        <span className="quoteTextWidget--start">{content[0]}</span>
        <span className="quoteTextWidget--main">
          {content.slice(1, content.length - 1)}
        </span>
        <span className="quoteTextWidget--end">
          {content[content.length - 1]}
        </span>
      </div>
    </blockquote>
  );
};

export default QuoteTextWidget;
