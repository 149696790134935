import React from 'react';
import {
  SourcesWidget as SourcesWidgetType,
  WidgetDefaultProps,
} from '../../types';

const SourcesWidget: React.FunctionComponent<
  SourcesWidgetType & WidgetDefaultProps
> = ({ content, className, id }) => {
  return (
    <div className={`sourcesWidget ${className ? className : ''}`} id={id}>
      <div
        className="sourcesWidget--content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default SourcesWidget;
