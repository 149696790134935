import { Link } from 'gatsby';
import React from 'react';
import * as uuid from 'uuid';
import { TagsWidgetType } from '../../parser';

const TagsWidget: React.FunctionComponent<TagsWidgetType> = ({ items }) => {
  return (
    <div className="tags">
      <div className="wrapper">
        <h2 className="tags--title">Tags</h2>
        <ul className="tags--list">
          {items.map((tag) => {
            return (
              <li className="tags--item" key={uuid.v4()}>
                <Link
                  to={`/tags/${tag.toLowerCase().trim().replace(/ /g, '-')}`}
                  className="btn btn_block"
                >
                  <span>{tag}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TagsWidget;
