import React from 'react';
import { ColumnsWithIconsWidgetType, WidgetDefaultProps } from '../../types';
import { Icon } from '../global';

const ColumnsWithIconsWidget: React.FunctionComponent<
  ColumnsWithIconsWidgetType & WidgetDefaultProps
> = ({ className, items }) => {
  return (
    <div className={`columnsWithIconsWidget ${className || ''}`}>
      <div className="columnsWithIconsWidget--wrapper">
        <ul className="columnsWithIconsWidget--list">
          {items.map((item, index) => {
            return (
              <li key={index} className="columnsWithIconsWidget--list-item">
                <div className="columnsWithIconsWidget--list-item-icon">
                  <Icon alt_text={item.icon.alt_text} uri={item.icon.uri} />
                </div>
                <div className="columnsWithIconsWidget--list-item-content">
                  <div
                    className="columnsWithIconsWidget--list-item-title"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  {item.text && (
                    <div
                      dangerouslySetInnerHTML={{ __html: item.text }}
                      className="columnsWithIconsWidget--list-item-text"
                    />
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ColumnsWithIconsWidget;
