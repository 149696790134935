import { Link } from 'gatsby';
import React from 'react';
import { RedirectBoxProps } from '../../types';
import RedirectBox from './redirect-box';

interface Props {
  list: Array<{
    group: {
      name: string;
      items: Array<{
        title: string;
        uri: string;
      }>;
    };
  }>;
  redirectBox?: RedirectBoxProps;
}

const Aside: React.FunctionComponent<Props> = ({ list, redirectBox }) => {
  return (
    <aside className="aside twoColSingle--aside">
      {list
        .filter((listItem) => listItem.group.items.length > 0)
        .map((listItem, listItemIndex) => {
          return (
            <div key={listItemIndex}>
              <div className="aside--title">{listItem.group.name}</div>
              <ul>
                {listItem.group.items.map((item, itemIndex) => {
                  return (
                    <li key={itemIndex}>
                      <Link to={item.uri}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      {redirectBox ? (
        <RedirectBox
          title={redirectBox.title}
          description={redirectBox.description}
          path={redirectBox.path}
        />
      ) : (
        ''
      )}
    </aside>
  );
};

export default Aside;
