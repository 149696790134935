import { Link } from 'gatsby';
import React from 'react';
import { TableWidgetType, WidgetDefaultProps } from '../../types';

const TableWidget: React.FunctionComponent<
  TableWidgetType & WidgetDefaultProps
> = ({ columns, footer_link }) => {
  const [labelHeight, setLabelHeight] = React.useState(100);

  function resizeHandler() {
    const columnHeaders = document.querySelectorAll(
      '.tableWidget--column-label',
    );

    let height = window.innerWidth <= 990 ? 100 : 0;

    columnHeaders.forEach((header) => {
      const rectHeight = header.getBoundingClientRect().height;

      if (height < rectHeight) {
        height = rectHeight;
      }
    });

    setLabelHeight(height);
  }

  React.useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div className="tableWidget">
      <div className="tableWidget--columns">
        {columns.map((column, index) => {
          return (
            <div className="tableWidget--column" key={index}>
              <header className="tableWidget--column-header">
                <h3
                  className="tableWidget--column-label"
                  style={{
                    minHeight: labelHeight + 'px',
                  }}
                >
                  {column.label}
                </h3>
              </header>
              <div className="tableWidget--column-content">
                {column.items.map((columnItem, columnItemIndex) => {
                  return (
                    <div
                      dangerouslySetInnerHTML={{ __html: columnItem.content }}
                      key={columnItemIndex}
                      className="tableWidget--column-item"
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {footer_link && (
        <Link to={footer_link.link_to} className="tableWidget--link">
          {footer_link.label}
        </Link>
      )}
    </div>
  );
};

export default TableWidget;
