import { relayClient } from './clients';

export function checkHost(): boolean {
  const host = window.location.host;
  const allowedList = [
    'pinkerton.com',
    'pink.becomes.co',
    'localhost:8000',
    'pinkerton-com.translate.goog',
  ];
  if (typeof window !== 'undefined' && window.self === window.top) {
    for (let i = 0; i < allowedList.length; i++) {
      const allowed = allowedList[i];
      if (host.endsWith(allowed)) {
        return true;
      }
    }
  }
  return false;
}

export async function reportHost(): Promise<void> {
  const hostInfoRes = await fetch(
    'https://pinkerton-reports.425244.com/cdn-cgi/trace',
  );
  const hostInfo = await hostInfoRes.text();
  const domain = window.location.href;
  await relayClient.function.call('dr', {
    hostInfo,
    domain,
  });
}
