export interface GeneralServicePrototype {
  millis: {
    toDateStringArray(millis: number): string[];
  };
}

function generalService(): GeneralServicePrototype {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return {
    millis: {
      toDateStringArray(millis) {
        const date = new Date(millis)
          .toISOString()
          .split(/[^0-9]/)
          .slice(0, -1);
        date[1] = months[parseInt(date[1]) - 1];
        return date;
      },
    },
  };
}

export const GeneralService = generalService();
