import React from 'react';
import {
  MediaWidget as MediaWidgetType,
  WidgetDefaultProps,
} from '../../types';
import { Image } from '../global';

const MediaWidget: React.FunctionComponent<
  MediaWidgetType & WidgetDefaultProps & { minImageWidth?: number }
> = ({ media, alt_text, onImageClick, caption, minImageWidth }) => {
  return (
    <div className="mediaWidget">
      <Image
        onClick={() => {
          if (onImageClick) {
            onImageClick(media);
          }
        }}
        src={media}
        alt={alt_text ? alt_text : 'Media widget'}
        minWidth={minImageWidth}
      />
      {caption && (
        <div
          className="mediaWidget--caption"
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      )}
    </div>
  );
};

export default MediaWidget;
