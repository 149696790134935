import React from 'react';
import Image from './image';

const iconCache: {
  [path: string]: string;
} = {};

interface Props {
  alt_text: string;
  uri: string;
}

const Icon: React.FC<Props> = (props) => {
  const [src, setSrc] = React.useState(
    iconCache[props.uri] ? iconCache[props.uri] : '',
  );

  React.useEffect(() => {
    if (!iconCache[props.uri]) {
      fetch(props.uri)
        .then(async (res) => {
          const svgText = await res.text();
          setSrc(svgText);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <div className="cms-icon">
      {src ? (
        <div dangerouslySetInnerHTML={{ __html: src }} />
      ) : (
        <Image src={props.uri} alt={props.alt_text} />
      )}
    </div>
  );
};

export default Icon;
