import React from 'react';
import {
  ImageFloatWidget as ImageFloatWidgetType,
  WidgetDefaultProps,
} from '../../types';
import { Image } from '../global';

const ImageFloatWidget: React.FunctionComponent<
  ImageFloatWidgetType & WidgetDefaultProps
> = ({ id, className, float, image, image_alt_text, onImageClick }) => {
  return (
    <div
      id={id}
      onClick={() => {
        if (onImageClick) {
          onImageClick(image);
        }
      }}
      className={`imageFloat ${
        float.selected ? float.selected.toLowerCase() : ''
      }${className ? ' ' + className : ''}`}
    >
      <Image src={image} className="imageFloat--image" alt={image_alt_text} />
    </div>
  );
};

export default ImageFloatWidget;
