import React from 'react';
import { ButtonWidgetType, WidgetDefaultProps } from '../../types';
import { Link } from 'gatsby';

const ButtonWidget: React.FunctionComponent<
  ButtonWidgetType & WidgetDefaultProps
> = ({ className, label, url, position, dark, theme }) => {
  return url ? (
    <Link
      className={`buttonWidget btn btn_block ${
        className || ''
      } buttonWidget_${position.selected?.toLowerCase()} ${
        dark ? 'darkBg' : ''
      } ${
        theme.selected?.toLowerCase() === 'transparent' ? 'btn_transparent' : ''
      }`}
      to={url}
    >
      <span>{label}</span>
    </Link>
  ) : (
    <button
      className={`buttonWidget btn btn_block ${
        className || ''
      } buttonWidget_${position.selected?.toLowerCase()} ${
        theme.selected?.toLowerCase() === 'transparent' ? 'btn_transparent' : ''
      }`}
    >
      <span>{label}</span>
    </button>
  );
};

export default ButtonWidget;
