import React from 'react';
import {
  AddressListWidget as AddressListWidgetType,
  WidgetDefaultProps,
} from '../../types';
import * as uuid from 'uuid';

const AddressListWidget: React.FunctionComponent<
  AddressListWidgetType & WidgetDefaultProps
> = ({ items }) => {
  return (
    <ul className="addressList">
      {items.map((item) => {
        return <li key={uuid.v4()}>{item}</li>;
      })}
    </ul>
  );
};

export default AddressListWidget;
