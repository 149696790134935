import { Link } from 'gatsby';
import React from 'react';
import { RelatedWidgetType } from '../../parser';
import Image from '../global/image';

const RelatedWidget: React.FunctionComponent<RelatedWidgetType> = ({
  title,
  items,
  slug_prefix,
}) => {
  const listRef = React.useRef<HTMLUListElement>(null);

  function truncateDescription(maxLines: number) {
    const items = listRef.current?.querySelectorAll('li');

    if (!items) return;

    items.forEach((item: HTMLLIElement) => {
      const description = item.querySelector('.related--item-description');
      const readMore = item.querySelector(
        '.related--item-readMore',
      ) as HTMLDivElement;
      const truncate = item.querySelector(
        '.related--item-truncate',
      ) as HTMLDivElement;

      if (!description || !readMore || !truncate) return;

      const words = description.querySelectorAll('span');

      let line = 1;
      let totalWidth = 0;

      const readMoreWidth = readMore.clientWidth;
      const truncateWidth = truncate.clientWidth;
      const descriptionWidth = description.clientWidth;

      words?.forEach((word: HTMLSpanElement, index) => {
        word.classList.remove('vh');

        const wordWidth = word.getBoundingClientRect().width;
        const nextWordWidth = words[index + 1]?.getBoundingClientRect().width;

        totalWidth += wordWidth;

        if (line < maxLines) {
          if (totalWidth > descriptionWidth) {
            line++;
            totalWidth = wordWidth;
          }
        } else if (line === maxLines) {
          if (
            totalWidth + nextWordWidth >
            descriptionWidth - readMoreWidth - truncateWidth
          ) {
            line++;
          }
        } else {
          word.classList.add('vh');
        }
      });
    });
  }

  React.useEffect(() => {
    if (window.innerWidth <= 414) {
      truncateDescription(5);
    } else {
      truncateDescription(4);
    }

    const mediaQuery = '(max-width: 414px)';
    const mediaQueryList = window.matchMedia(mediaQuery);

    // Resize handling
    if (typeof mediaQueryList.addEventListener !== 'undefined') {
      mediaQueryList.addEventListener('change', (event) => {
        if (event.matches) {
          truncateDescription(5);
        } else {
          truncateDescription(4);
        }
      });
    } else if (typeof mediaQueryList.addListener !== 'undefined') {
      mediaQueryList.addListener((event) => {
        if (event.matches) {
          truncateDescription(5);
        } else {
          truncateDescription(4);
        }
      });
    }
  }, []);

  return (
    <div className="related">
      <div className="wrapper">
        <h2 className="related--title">{title}</h2>
        <ul className="related--list" ref={listRef}>
          {items
            .filter((e) => e.slug)
            .map((item, index) => {
              const description =
                item?.description
                  ?.split(' ')
                  .map((e) => `<span>${e} </span>`)
                  .join('') +
                  `<div class="related--item-truncate">...</div>
              <div class="related--item-readMore">Read more</div>`.replace(
                    '  ',
                    ' ',
                  ) || '';

              return (
                <li className="related--item" key={index}>
                  <Link to={`${slug_prefix || '/solutions/'}${item.slug}`}>
                    <div className="related--item-overlay" />
                    {item.image && (
                      <Image
                        src={item.image.uri}
                        alt={item.image.alt_text}
                        minWidth={600}
                        className="related--item-image"
                      />
                    )}
                    <div className="related--item-text">
                      <h3 className="related--item-title">{item.title}</h3>
                      <div className="related--item-inner">
                        <div
                          className="related--item-description"
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default RelatedWidget;
