import React from 'react';
import { Icon } from './index';
import { AsideWithIconsGroupType } from '../../types/groups/aside-with-icons';
import { Link } from 'gatsby';

interface Props {
  data: AsideWithIconsGroupType;
  className?: string;
}

const AsideWithIcons: React.FunctionComponent<Props> = ({ data }) => {
  return (
    <aside
      className={`asideWithIcons ${
        data.dark ? 'asideWithIcons_dark' : ''
      } twoColSingle--aside`}
    >
      {data.title?.trim() && (
        <div className="asideWithIcons--title">{data.title}</div>
      )}
      {data.items && data.items.filter((e) => e.icon.uri).length > 0 && (
        <ul className="asideWithIcons--list">
          {data.items.map((item, index) => {
            return (
              <li key={index} className="asideWithIcons--item">
                <div className="asideWithIcons--item-icon">
                  <Icon alt_text={item.icon.alt_text} uri={item.icon.uri} />
                </div>
                <div className="asideWithIcons--item-content">
                  {item.url ? (
                    <Link to={item.url} className="asideWithIcons--item-title">
                      {item.title}
                    </Link>
                  ) : (
                    <div className="asideWithIcons--item-title">
                      {item.title}
                    </div>
                  )}
                  {item.list.length > 0 && (
                    <ul className="asideWithIcons--subList">
                      {item.list.map((subItem, subIndex) => {
                        return (
                          <li
                            key={subIndex}
                            className="asideWithIcons--subItem"
                          >
                            {subItem}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </aside>
  );
};

export default AsideWithIcons;
