import React from 'react';
import { Icon } from '..';
import { IconsTextListWidgetType, WidgetDefaultProps } from '../../types';

const IconsTextListWidget: React.FunctionComponent<
  IconsTextListWidgetType & WidgetDefaultProps
> = ({ className, title, list }) => {
  return (
    <div className={`iconsTextListWidget ${className || ''}`}>
      <div className="wrapper">
        {title && <h2 className="iconsTextListWidget--title">{title}</h2>}
        {list.length > 0 && (
          <ul className="iconsTextListWidget--list">
            {list.map((item, index) => {
              return (
                <li key={index} className="iconsTextListWidget--list-item">
                  <div className="iconsTextListWidget--list-item-icon">
                    <Icon alt_text={item.icon.alt_text} uri={item.icon.uri} />
                  </div>
                  <div
                    className="iconsTextListWidget--list-item-title"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  {item.text && (
                    <div
                      className="iconsTextListWidget--list-item-text"
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default IconsTextListWidget;
