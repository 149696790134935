import React from 'react';
import { ContentWithVisualWidgetType, WidgetDefaultProps } from '../../types';
import { Image } from '../global';

const ContentWithVisualWidget: React.FunctionComponent<
  ContentWithVisualWidgetType & WidgetDefaultProps
> = ({ id, className, content, image, background_color, visual_position }) => {
  return (
    <div
      id={id}
      className={`contentWithVisualWidget ${
        visual_position?.selected
          ? 'contentWithVisualWidget_' + visual_position.selected.toLowerCase()
          : ''
      }${className ? ' ' + className : ''}`}
      style={{ backgroundColor: background_color || '#fff' }}
    >
      <div className="wrapper">
        <div className="contentWithVisualWidget--inner">
          <div
            className="contentWithVisualWidget--content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <div className="contentWithVisualWidget--image-wrapper">
            <Image
              className="contentWithVisualWidget--image"
              src={image.uri}
              alt={image.alt_text}
              minWidth={1600}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentWithVisualWidget;
