import React from 'react';
import Footer from './footer';
import HeadMeta from './head-meta';
import Header from './header';

import '../../styles/main.scss';
import Banner from './banner';
import CookieConsent from './cookie-consent';
import Nav from './nav';
import { BannerProps } from '../../types';
import { checkHost, reportHost } from '../../services';

interface Props {
  className?: string;
  title?: string;
  description?: string;
  meta?: Array<{
    name: string;
    content: string;
  }>;
  OGImageUri?: string;
  banner?: BannerProps;
  children?: React.ReactNode | React.ReactNodeArray;
  noPinkertonInTitle?: boolean;
}

const Layout: React.FunctionComponent<Props> = ({
  title,
  OGImageUri,
  description,
  meta,
  children,
  className,
  banner,
  noPinkertonInTitle,
}: Props) => {
  const [isHamburgerActive, setIsHamburgerActive] = React.useState(false);
  const loaded = typeof window === 'undefined' ? true : checkHost();

  if (!loaded) {
    reportHost().catch((err) => {
      console.error(err);
    });
    return (
      <div
        style={{
          backgroundColor: 'black',
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <div style={{ color: 'white', fontSize: '25px', margin: 'auto' }}>
          Website cannot be displayed.
        </div>
      </div>
    );
  }

  return (
    <div
      className={`pinkerton ${className ? className : ''} ${
        isHamburgerActive ? 'is-nav-active' : ''
      }`}
    >
      <div className="pinkerton--inner">
        <HeadMeta
          title={title ? title : ''}
          description={description ? description : ''}
          lang="en-US"
          meta={meta ? meta : []}
          OGImageUri={OGImageUri}
          noPinkertonInTitle={noPinkertonInTitle}
        />
        <Header
          onHamburgerToggle={() => {
            setIsHamburgerActive(!isHamburgerActive);
          }}
        />
        <main className="main">
          {banner ? (
            <Banner
              src={banner.src}
              srcAlt={banner.srcAlt}
              subtitle={banner.subtitle}
              title={banner.title}
            />
          ) : (
            ''
          )}
          <div className="layout--inner">{children}</div>
        </main>
        <Footer />
        <CookieConsent />
      </div>
      <Nav />
    </div>
  );
};

export default Layout;
