import * as uuid from 'uuid';
import React from 'react';
import {
  AccordionWidget as AccordionWidgetType,
  WidgetDefaultProps,
} from '../../types';

const AccordionWidget: React.FunctionComponent<
  AccordionWidgetType & WidgetDefaultProps
> = ({ id, className, items, children }) => {
  return (
    <div>
      {items.map((item) => {
        return (
          <div id={id} key={uuid.v4()} className={`accordion ${className}`}>
            <button
              onClick={(event) => {
                const target = event.target as HTMLButtonElement;
                target.parentElement?.classList.toggle('expand');
              }}
              className="btn btn_block accordion--btn"
            >
              <span>{item.name}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus"
              >
                <path d="M12 5v14M5 12h14" />
              </svg>
            </button>
            {!children && (
              <div
                className="accordion--inner"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            )}
            {children && (
              <div className="accordion--inner">{children && children}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AccordionWidget;
