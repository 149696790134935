import { Link } from 'gatsby';
import React from 'react';
import { FooterItems } from '../../data';

const Footer: React.FunctionComponent = () => {
  return (
    <footer className="footer">
      <div className="footer--top">
        <nav className="footer--top-nav">
          <ul className="footer--top-list">
            {FooterItems.map((navItem, index) => {
              return (
                <li key={index} className="footer--top-list-item">
                  <div className="footer--top-list-item-label">
                    {navItem.label}
                  </div>
                  <ul className="footer--top-subList">
                    {navItem.items.map((subNavItem, subIndex) => {
                      return (
                        <li key={subIndex} className="footer--top-subList-item">
                          {subNavItem.to.startsWith('/') ? (
                            <Link
                              to={subNavItem.to}
                              className="footer--top-subList-item-link"
                            >
                              <span>{subNavItem.label}</span>
                            </Link>
                          ) : (
                            <a
                              href={subNavItem.to}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="footer--top-subList-item-link"
                            >
                              <span>
                                {subNavItem.label}
                                <sup>➚</sup>
                              </span>
                            </a>
                          )}
                        </li>
                      );
                    })}
                    {index === FooterItems.length - 1 && (
                      <ul className="footer--socialList">
                        <li className="footer--socialList-item">
                          <a
                            href="https://www.linkedin.com/company/pinkerton-corporate-risk-management/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              aria-hidden="true"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z"
                              ></path>
                            </svg>
                            <span className="sr-only">
                              Pinkerton on Linkedin
                            </span>
                          </a>
                        </li>
                        <li className="footer--socialList-item">
                          <a
                            href="https://vimeo.com/user168978093"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              aria-hidden="true"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="1620.033 290.983 18.933 16.383"
                            >
                              <path
                                d="M1638.945 294.787c-.084 1.84-1.369 4.358-3.856 7.557-2.57 3.344-4.747 5.015-6.527 5.015-1.103 0-2.035-1.018-2.798-3.053l-1.526-5.596c-.567-2.036-1.175-3.053-1.824-3.053-.14 0-.634.3-1.482.891l-.89-1.146c.926-.813 1.845-1.633 2.759-2.46 1.246-1.076 2.18-1.64 2.805-1.699 1.469-.145 2.376.86 2.716 3.018.367 2.326.622 3.77.764 4.333.425 1.928.893 2.892 1.402 2.89.394 0 .988-.626 1.782-1.875.793-1.251 1.218-2.203 1.273-2.855.114-1.08-.31-1.62-1.273-1.62-.483.008-.96.113-1.4.31.932-3.042 2.707-4.522 5.328-4.44 1.943.058 2.86 1.32 2.747 3.782Z"
                                fill="#fff"
                                fillRule="evenodd"
                                data-name="vimeo"
                              />
                            </svg>
                            <span className="sr-only">Pinkerton on Vimeo</span>
                          </a>
                        </li>
                        <li className="footer--socialList-item">
                          <a
                            href="https://www.facebook.com/pinkertoncorporate"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              aria-hidden="true"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 264 512"
                            >
                              <path
                                fill="currentColor"
                                d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
                              ></path>
                            </svg>
                            <span className="sr-only">
                              Pinkerton on Facebook
                            </span>
                          </a>
                        </li>
                        <li className="footer--socialList-item">
                          <a
                            href="https://twitter.com/Pinkerton"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              aria-hidden="true"
                              width={16}
                              height={17}
                              viewBox="0 0 1200 1227"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                                fill="white"
                              />
                            </svg>
                            <span className="sr-only">
                              Pinkerton on Twitter
                            </span>
                          </a>
                        </li>
                      </ul>
                    )}
                  </ul>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <div className="footer--bottom">
        <nav className="footer--bottom-nav">
          <ul className="footer--bottom-list">
            <li className="footer--bottom-list-item">
              <Link to="/terms-of-use" target="_self">
                Terms of Use
              </Link>
            </li>
            <li className="footer--bottom-list-item">
              <Link to="/privacy-policy" target="_self">
                Privacy Policy
              </Link>
            </li>
            <li className="footer--bottom-list-item">
              <Link to="/data-privacy-framework" target="_self">
                Data Privacy Framework
              </Link>
            </li>
            <li className="footer--bottom-list-item">
              <Link to="/antipiracy" target="_self">
                Antipiracy
              </Link>
            </li>
          </ul>
        </nav>
        <p className="footer--copyright">
          ©{new Date().getFullYear()} Pinkerton Consulting & Investigations,
          Inc. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
