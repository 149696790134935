import React from 'react';
import { ImageService } from '../../services';

interface Props {
  className?: string;
  style?: React.HTMLAttributes<HTMLImageElement>;
  src: string;
  alt: string;
  Key?: string;
  onClick?: () => void;
  autoMaxWidth?: boolean;
  minWidth?: number;
  role?: string;
  width?: number;
  height?: number;
  noOpacityTransition?: boolean;
}

const Image: React.FunctionComponent<Props> = ({
  className,
  style,
  src,
  alt,
  Key,
  onClick,
  autoMaxWidth,
  minWidth,
  role,
  width: widthAttr,
  height: heighAttr,
  noOpacityTransition,
}: Props) => {
  const [width, setWidth] = React.useState(350);
  const [opacity, setOpacity] = React.useState(noOpacityTransition ? 1 : 0);
  const ref = React.useRef<HTMLImageElement | null>(null);
  const srcParts = src.split('.');
  const firstPart = srcParts.slice(0, srcParts.length - 1).join('.');
  const lastPart = srcParts[srcParts.length - 1];
  const parsable = ImageService.parsable(lastPart);

  const parse = async (element: HTMLPictureElement) => {
    if (!element.parentElement) {
      return;
    }
    let wid = ImageService.closest(element.parentElement.offsetWidth);
    if (minWidth && wid < minWidth) {
      wid = ImageService.closest(minWidth);
    }
    if (autoMaxWidth) {
      element.style.setProperty('max-width', `${wid}px`);
    }
    setWidth(wid);
    setOpacity(1);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const propRole: { [role: string]: any } = {};
  role ? (propRole.role = role) : null;

  React.useEffect(() => {
    setTimeout(() => {
      if (parsable === true) {
        if (ref.current) {
          parse(ref.current);
        }
      }
    }, 50);
  }, [src, alt, Key]);

  if (parsable === true) {
    return (
      <picture>
        <source srcSet={`${firstPart}-${width}.webp`} />
        <img
          ref={ref}
          onClick={onClick}
          draggable="false"
          className={className}
          src={`${firstPart}-${width}.${lastPart}`}
          alt={alt}
          title={alt}
          {...propRole}
          loading="lazy"
          width={widthAttr ? widthAttr : 1280}
          height={heighAttr ? heighAttr : 1280}
          style={{
            ...style,
            opacity: opacity,
            transition: 'opacity 1s ease',
          }}
        />
      </picture>
    );
  } else {
    return (
      <img
        onClick={onClick}
        key={Key}
        draggable="false"
        className={className}
        style={{
          ...style,
          height: 'auto',
        }}
        src={src}
        alt={alt}
        title={alt}
        loading="lazy"
        width={widthAttr ? widthAttr : 1280}
        height={heighAttr ? heighAttr : 1280}
        {...propRole}
      />
    );
  }
};

export default Image;
