import React from 'react';
import {
  MetaTagsWidget as MetaTagsWidgetType,
  WidgetDefaultProps,
} from '../../types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { ImageService } from '../../services';

const MetaTagsWidget: React.FunctionComponent<
  MetaTagsWidgetType & WidgetDefaultProps
> = ({ title, description, image }) => {
  const {
    site,
  }: {
    site: {
      siteMetadata: {
        origin: string;
      };
    };
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            origin
          }
        }
      }
    `,
  );

  let metaOGImage;

  if (image) {
    metaOGImage =
      site.siteMetadata.origin + ImageService.toSmallest(image, 1920, 1920);
  }

  return (
    <>
      {title && (
        <Helmet
          title={title}
          meta={[
            {
              property: `og:title`,
              content: title,
            },
            {
              name: `twitter:title`,
              content: title,
            },
          ]}
        ></Helmet>
      )}
      {description && (
        <Helmet
          meta={[
            {
              name: `description`,
              content: description,
            },
            {
              property: `og:description`,
              content: description,
            },
            {
              name: `twitter:description`,
              content: description,
            },
          ]}
        ></Helmet>
      )}
      {image && (
        <Helmet
          meta={[
            {
              property: `og:image`,
              content: metaOGImage ? `${metaOGImage}` : undefined,
            },
            {
              property: `twitter:image`,
              content: metaOGImage ? `${metaOGImage}` : undefined,
            },
          ]}
        ></Helmet>
      )}
    </>
  );
};

export default MetaTagsWidget;
