import { Link } from 'gatsby';
import React from 'react';
import { ReferenceWidgetType } from '../../types';
import { Image } from '../global';

const ReferenceWidget: React.FunctionComponent<ReferenceWidgetType> = ({
  reference,
}) => {
  const items = Object.keys(reference)
    .map((key) => {
      const referenceItems = (reference as any)[key];

      return referenceItems.map((item: any) => {
        return {
          title: item.title,
          slug: item.slug,
          cover_image: item.cover_image,
          description: item.description,
        };
      });
    })
    .flat();

  return (
    <div className="referenceWidget">
      <div className="wrapper">
        <ul className="referenceWidget--list">
          {items.map((item, index) => {
            return (
              <li key={index} className="referenceWidget--list-item">
                <Link
                  to={item.slug}
                  className="referenceWidget--list-item-link"
                >
                  <Image
                    src={item.cover_image.uri}
                    alt={item.cover_image.alt_text}
                    minWidth={600}
                    className="referenceWidget--list-item-image"
                  />
                  <div className="referenceWidget--list-item-text">
                    <h2 className="referenceWidget--list-item-title">
                      {item.title}
                    </h2>
                    <p className="referenceWidget--list-item-description">
                      {item.description}
                    </p>
                    <div
                      className="btn btn_block"
                      style={{ maxWidth: 'max-content' }}
                    >
                      <span>Learn more</span>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ReferenceWidget;
